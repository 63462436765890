import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as TichuEngineLogo } from "../assets/tichu-engine-icon-and-text.svg";
import { UserContext } from "../contexts/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownMenu, DropdownItemButton } from "./Dropdown";
import cog from "../assets/bxs-cog.svg";
import logout from "../assets/bx-log-out.svg";
import { getAvatarUrl, getParticipantAvatarUrl } from "../misc/Avatars";
import { LobbyContext } from "../contexts/LobbyContext";
import { DmTable } from "../client/server-types-python";

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 8px 8px 0 8px;
    margin-bottom: 10px;
`;

const NavLeft = styled.div`
    color: white;
    display: flex;
    justify-content: start;
    align-items: flex-end;
    gap: 8px;
    flex: 1;
    padding-left: 6px;

    &:hover {
        cursor: pointer;
    }
`;

const NavRight = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
    flex: 1;
    padding-right: 6px;
`;

const TabButtonStrip = styled.div`
    display: flex;
    justify-content: start;
    gap: 12px;
    flex: 1;
    padding-left: 18px;
    align-items: flex-end;
`;

const NavBarTabButton = styled.button<{ $active?: boolean }>`
    background-color: ${(props) => props.theme.colors.primary_light};
    border: none;
    color: ${(props) => props.theme.colors.black_text};
    padding: 10px 15px 6px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 14px 14px 0 0;
    position: relative;
    display: flex;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_very_light};
    }

    ${(props) =>
        props.$active &&
        `
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            right: -4px;
            bottom: 0;
            border: 5px solid white;
            border-bottom: none;
            border-radius: 18px 18px 0 0;
            pointer-events: none;
        }
    `}
`;

const DarkNavBarTabButton = styled(NavBarTabButton)`
    background-color: ${(props) => props.theme.colors.primary_dark};
    color: ${(props) => props.theme.colors.white_text};
    &:hover {
        background-color: ${(props) => props.theme.colors.primary_darker};
    }
`;

const UserAvatar = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 2px;
    margin-left: 2px;
`;

const UserButton = styled(DarkNavBarTabButton)`
    display: flex;
    align-items: center;
    padding: 7px 15px;
`;

const AvatarPlaceholder = styled.span`
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "Arial", sans-serif;
`;

export function NavBar(): JSX.Element {
    const userContext = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const lobbyContext = useContext(LobbyContext);
    const tablesState = lobbyContext?.tablesState ?? [];
    const { tableId } = useParams();

    const getCurrentPageTable = () => {
        if (!tableId) {
            return null;
        }
        return tablesState.find((table) => table.id === tableId) ?? null;
    };

    const getUserActiveGameTables = () => {
        if (!userContext) {
            return [];
        }
        return tablesState.filter((table) =>
            table.game.participants.some((participant) => participant && participant.user && participant.user.id === userContext.user.id)
        );
    };

    const currentPageTable = getCurrentPageTable();
    const userActiveGameTables = getUserActiveGameTables();
    const currentPageTableInUserActiveGameTables =
        currentPageTable !== null && userActiveGameTables.find((table) => table.id === currentPageTable.id);

    const getTableTabButtonContent = (table: DmTable) => {
        const avatars = table.game.participants.map((participant) => getParticipantAvatarUrl(participant));
        return avatars.map((avatar, index) =>
            avatar ? (
                <UserAvatar src={avatar} key={"tab_avatar_" + table.id + "_" + index} />
            ) : (
                <AvatarPlaceholder key={"tab_avatar_" + table.id + "_" + index}>?</AvatarPlaceholder>
            )
        );
    };

    return (
        <Nav>
            <NavLeft>
                <TichuEngineLogo
                    onClick={() => navigate("/")}
                    height="50px"
                    width="172px"
                    style={{ marginBottom: "8px", filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))" }}
                />
                <TabButtonStrip>
                    <DarkNavBarTabButton onClick={() => navigate("/")} $active={location.pathname === "/"}>
                        Lobby
                    </DarkNavBarTabButton>
                    <>
                        {userActiveGameTables.map((table) => {
                            return (
                                <DarkNavBarTabButton
                                    key={table.id}
                                    $active={table.id === tableId}
                                    onClick={() => {
                                        navigate(`/table/${table.id}`);
                                    }}
                                >
                                    {getTableTabButtonContent(table)}
                                </DarkNavBarTabButton>
                            );
                        })}
                    </>
                    {currentPageTable && !currentPageTableInUserActiveGameTables && (
                        <DarkNavBarTabButton
                            $active={currentPageTable.id === tableId}
                            onClick={() => navigate(`/table/${currentPageTable.id}`)}
                        >
                            {getTableTabButtonContent(currentPageTable)}
                        </DarkNavBarTabButton>
                    )}
                </TabButtonStrip>
            </NavLeft>
            <NavRight>
                <DarkNavBarTabButton $active={location.pathname === "/about"} onClick={() => navigate("/about")}>
                    About
                </DarkNavBarTabButton>
                {userContext ? (
                    <DropdownMenu
                        triggerButton={
                            <UserButton $active={location.pathname === "/settings"} onClick={() => navigate("/settings")}>
                                <UserAvatar src={getAvatarUrl("", userContext.user.avatar)} />
                                {userContext.user.display_name}
                            </UserButton>
                        }
                    >
                        {location.pathname !== "/settings" && (
                            <DropdownItemButton onClick={() => navigate("/settings")}>
                                <img src={cog} alt="settings-cog" height="16px" style={{ marginRight: "6px", marginBottom: "-3px" }} />
                                Settings
                            </DropdownItemButton>
                        )}
                        <DropdownItemButton
                            onClick={() => {
                                userContext.logoutFunc();
                                navigate("/");
                            }}
                        >
                            <img
                                src={logout}
                                alt="log-out"
                                height="16px"
                                style={{ marginRight: "6px", marginBottom: "-3px", marginLeft: "-2px" }}
                            />
                            Log Out
                        </DropdownItemButton>
                    </DropdownMenu>
                ) : null}
            </NavRight>
        </Nav>
    );
}
