import React from "react";
import styled from "styled-components";
import ScoreDisplay from "./ScoreDisplay";
import HandNumberDisplay from "./HandNumberDisplay";

interface HandAndScoreDisplayProps {
    nsScore: number;
    ewScore: number;
    handNumber: number;
    showScoreModal: () => void;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

const HandAndScoreDisplay: React.FC<HandAndScoreDisplayProps> = ({ nsScore, ewScore, handNumber, showScoreModal }) => {
    const handleClick = () => {
        showScoreModal();
    };

    return (
        <>
            <Container onClick={handleClick}>
                <ScoreDisplay nsScore={nsScore} ewScore={ewScore} />
                <HandNumberDisplay handNumber={handNumber} />
            </Container>
        </>
    );
};

export default HandAndScoreDisplay;
