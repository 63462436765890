import React from "react";
import styled from "styled-components";
import { UserContext } from "../contexts/UserContext";
import { DmGameParticipant, DmTable, GameStatus, TableStatus } from "../client/server-types-python";
import theme from "../theme";
import { getBotAvatarUrl } from "../misc/Avatars";
import { getAvatarUrl } from "../misc/Avatars";
import { useNavigate } from "react-router-dom";
import { getGameModeLabel } from "../client/util";

const OverallContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TablesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1020px;
`;

const TableInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    width: 90px;
    height: 100%;
    margin-top: 30px;
`;

const TableInfo = styled.p`
    margin: 0px;
    text-align: left;
`;

const TablePreviewDiv = styled.button<{ $status: TableStatus }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0px;
    margin: 10px;
    padding: 0px;
    width: 320px;
    height: 170px;
    background-color: ${(props) =>
        props.$status === TableStatus.OPEN || props.$status === TableStatus.READY
            ? props.theme.colors.primary_light
            : props.$status === TableStatus.PLAYING || props.$status === TableStatus.LIMBO
            ? props.theme.colors.primary_darker
            : props.theme.colors.replay_mode_dark};
    color: ${(props) =>
        props.$status === TableStatus.OPEN || props.$status === TableStatus.READY
            ? props.theme.colors.black_text
            : props.theme.colors.white_text};
    border-radius: 8px;
    position: relative;
    &:hover {
        background-color: ${(props) =>
            props.$status === TableStatus.OPEN || props.$status === TableStatus.READY
                ? props.theme.colors.primary_fairly_light
                : props.$status === TableStatus.PLAYING || props.$status === TableStatus.LIMBO
                ? props.theme.colors.primary_dark
                : props.theme.colors.replay_mode_medium};
    }
    &:active {
        background-color: ${(props) =>
            props.$status === TableStatus.OPEN || props.$status === TableStatus.READY
                ? props.theme.colors.primary_very_light
                : props.$status === TableStatus.PLAYING || props.$status === TableStatus.LIMBO
                ? props.theme.colors.primary_light
                : props.theme.colors.replay_mode_light};
    }
`;

const SeatsContainer = styled.div`
    display: grid;
    grid-template-areas:
        ". seat3 seat3 ."
        "seat2 seat2 seat4 seat4"
        ". seat1 seat1 .";
    gap: 6px;
    width: 210px;
    height: 150px;
    justify-content: center;
    align-content: center;
`;

const Seat = styled.div<{ $gridArea: string; $isCurrentUser: boolean; $isEmpty: boolean }>`
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    grid-area: ${(props) => props.$gridArea};
    background-color: ${(props) => (props.$isEmpty ? theme.colors.primary_dull : theme.colors.primary)};
    color: ${theme.colors.white_text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 25px;
    border: ${(props) => (props.$isCurrentUser ? "1px" : "0px")} solid ${(props) => props.theme.colors.white_text};
`;

const AvatarImage = styled.img`
    width: 20px;
    height: 20px;
    opacity: 0.8;
`;

const GiantBotIcon = styled.img`
    width: 80px;
    height: 80px;
`;

const MissingAvatarDiv = styled.div`
    width: 20px;
    height: 20px;
    opacity: 0.8;
`;

const CreateTableButton = styled(TablePreviewDiv)`
    margin: 10px 30px;
    height: 120px;
    gap: 20px;
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    font-size: 120px;
    &:hover {
        background-color: ${(props) => props.theme.colors.primary_medium};
    }
    &:active {
        background-color: ${(props) => props.theme.colors.primary_light};
    }
`;

const CreateTableSubtitle = styled.div`
    font-size: 20px;
`;

export function TablePreview(props: { table: DmTable; onClick: () => void; onJoinTable: (tableId: string) => void }): JSX.Element {
    const userContext = React.useContext(UserContext);
    // const userIsAt = userContext && props.table.game.participants.some((player) => player?.user?.id === userContext.user.id);
    // const joinable = (props.table.status === TableStatus.OPEN || props.table.status === TableStatus.LIMBO) && !userIsAt;

    const renderSeat = (participant: DmGameParticipant | null, index: number) => {
        return (
            <Seat
                key={index}
                $gridArea={`seat${index + 1}`}
                $isCurrentUser={participant?.user?.id === userContext?.user.id}
                $isEmpty={!participant}
            >
                {participant ? (
                    participant.user ? (
                        <>
                            <AvatarImage src={getAvatarUrl("", participant.user.avatar)} alt="Player avatar" />
                            <div>{participant.user.display_name}</div>
                        </>
                    ) : (
                        <>
                            <AvatarImage src={getBotAvatarUrl("")} alt="Bot avatar" />
                            <div>{participant.bot_name}</div>
                        </>
                    )
                ) : (
                    <>
                        <MissingAvatarDiv>---</MissingAvatarDiv>
                    </>
                )}
            </Seat>
        );
    };

    return (
        <TablePreviewDiv $status={props.table.status} onClick={props.onClick}>
            <TableInfoColumn>
                <TableInfo>
                    <b>{props.table.owner.display_name}'s Table</b>
                </TableInfo>
                <TableInfo>{getGameModeLabel(props.table.settings.game_mode)}</TableInfo>
                <TableInfo>
                    {props.table.game.status === GameStatus.NOT_STARTED
                        ? "Pre-game"
                        : props.table.game.status === GameStatus.COMPLETE
                        ? "Complete"
                        : `Hand #${props.table.game.game_hands.length}`}
                </TableInfo>
                {/* {joinable && <button onClick={() => props.onJoinTable(props.table.id)}>Join</button>} */}
            </TableInfoColumn>
            <SeatsContainer>
                {/* don't mess with the order of these players */}
                {renderSeat(props.table.game.participants[2], 2)}
                {renderSeat(props.table.game.participants[1], 1)}
                {renderSeat(props.table.game.participants[3], 3)}
                {renderSeat(props.table.game.participants[0], 0)}
            </SeatsContainer>
        </TablePreviewDiv>
    );
}

const tableSortValue = (table: DmTable) => {
    if (table.status === TableStatus.OPEN || table.status === TableStatus.READY) {
        return 0;
    }
    if (table.status === TableStatus.PLAYING || table.status === TableStatus.LIMBO) {
        return 1;
    }
    return 2;
};

export function Lobby({
    tables,
    onCreateTable,
    onCreateBotTable,
    onJoinTable,
}: {
    tables: DmTable[];
    onCreateTable: () => void;
    onCreateBotTable: () => void;
    onJoinTable: (tableId: string) => void;
}): JSX.Element {
    const navigate = useNavigate();
    const sortedTables = tables.sort((a, b) => {
        return tableSortValue(a) - tableSortValue(b);
    });

    return (
        <OverallContainer>
            <TablesContainer>
                <CreateTableButton $status={TableStatus.OPEN} onClick={onCreateTable}>
                    +<CreateTableSubtitle>New Table</CreateTableSubtitle>
                </CreateTableButton>
                <CreateTableButton $status={TableStatus.OPEN} onClick={onCreateBotTable}>
                    <GiantBotIcon src={getBotAvatarUrl("")} alt="Bot avatar" />
                    <CreateTableSubtitle>Play vs Bots</CreateTableSubtitle>
                </CreateTableButton>
                {sortedTables.map((table) => (
                    <TablePreview key={table.id} table={table} onClick={() => navigate(`/table/${table.id}`)} onJoinTable={onJoinTable} />
                ))}
            </TablesContainer>
        </OverallContainer>
    );
}
