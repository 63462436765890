import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DmPlayState, DmGame, DmTable, DmGameHand, DmHistoryEntry, GameStatus } from "../client/server-types-python";
import { NowplayState, ReplayState } from "../model/ReplayState";
import { getHandHistory } from "../model/HandHistoryUtil";
import { ApiClient } from "../client/ApiClient";
import { playDrum, playPop } from "../misc/SoundPlayer";
import { playBigDrum } from "../misc/SoundPlayer";
import { PosId } from "../client/basic-types";
import { UserContext } from "../contexts/UserContext";
import React from "react";

interface GameplayContextValue {
    // The hand and where to find it in the table's game
    displayedGameHandIndex: number;
    maxDisplayedGameHandIndex: number; // Hide entries past this from the user
    displayedGameHand: DmGameHand;

    // The play state and where to find it in the hand's history
    displayedHistoryIndex: number;
    maxDisplayedHistoryIndex: number; // Hide entries past this from the user
    displayedPlayState: DmPlayState;

    // Full history of the displayed hand
    fullDisplayedHandHistory: DmHistoryEntry[];
    // Truncated history of the displayed hand (capped at displayedHistoryIndex)
    truncatedHandHistory: DmHistoryEntry[];

    // Additional state derived from the play state above.
    // TODO
}

interface TableContextValue {
    table: DmTable;
    game: DmGame;

    loading: boolean;
    replayState: ReplayState;
    setReplayState: React.Dispatch<React.SetStateAction<ReplayState>>;
    nowplayState: NowplayState;
    setNowplayState: React.Dispatch<React.SetStateAction<NowplayState>>;

    playerSeat: PosId | -1;
    gameplayContext?: GameplayContextValue;

    handHistoryCache: (DmHistoryEntry[] | null)[];
}

export const TableContext = createContext<TableContextValue | null>(null);

interface TableContextProviderProps {
    table: DmTable;
    apiClient: ApiClient;
    children: React.ReactNode;
}

function defaultNowplayState(game: DmGame): NowplayState {
    return {
        nowplayHandCursor: game.game_hands.length > 0 ? game.game_hands.length - 1 : -1,
        nowplayMoveCursor: game.game_hands.length > 0 ? (game.game_hands[game.game_hands.length - 1]?.history?.length ?? 0) - 1 : -1,
        lastUpdateTime: Date.now(),
    };
}

export function TableContextProvider({ table, apiClient, children }: TableContextProviderProps) {
    const user = useContext(UserContext)?.user ?? null;
    const game = table.game;
    const gameIdRef = useRef(game.id);
    const [handHistoryCache, setHandHistoryCache] = useState<(DmHistoryEntry[] | null)[]>([]);
    const [loading, setLoading] = useState(false);
    const [calculating, setCalculating] = useState(false);
    const [replayState, setReplayState] = useState<ReplayState>({
        replayModeEnabled: false,
        replayHandCursor: 0,
        replayMoveCursor: 0,
    });
    const [nowplayState, setNowplayState] = useState<NowplayState>(defaultNowplayState(game));
    const playerSeat: PosId | -1 = user ? (game.participants.findIndex((player) => player?.user?.id === user.id) as PosId | -1) : -1;
    const nowplayStepDelay = [1500, 1100, 700, 400, 100][user?.user_settings?.speed ?? 2];

    const nowplayAdvanceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Reset various states when the game changes
    useEffect(() => {
        if (gameIdRef.current !== game.id) {
            setHandHistoryCache([]);
            setReplayState({
                replayModeEnabled: false,
                replayHandCursor: 0,
                replayMoveCursor: 0,
            });
            setNowplayState(defaultNowplayState(game));
            setCalculating(true);
        }
    }, [game]);

    // This is just to delay setting the gameIdRef by one render, so that the useMemo call
    // doesn't get too trigger-happy.
    useEffect(() => {
        if (calculating) {
            setCalculating(false);
            gameIdRef.current = game.id;
        }
    }, [calculating, game]);

    // Fetch replay state if pointing at an old hand
    useEffect(() => {
        if (gameIdRef.current !== game.id || calculating) {
            return;
        }
        if (replayState.replayModeEnabled && getHandHistory(game, replayState.replayHandCursor, handHistoryCache) === null) {
            setLoading(true);
            const response = apiClient.getGameHand(table.id, replayState.replayHandCursor);
            response.then((response) => {
                if (response instanceof Error) {
                    console.error(response);
                } else {
                    const newHandHistoryCache = [...handHistoryCache];
                    while (newHandHistoryCache.length <= replayState.replayHandCursor) {
                        newHandHistoryCache.push(null);
                    }
                    newHandHistoryCache[replayState.replayHandCursor] = response.history;
                    setHandHistoryCache(newHandHistoryCache);
                    setLoading(false);
                }
            });
        }
    }, [table, game, replayState, handHistoryCache, apiClient, calculating]);

    // Update nowplay state
    useEffect(() => {
        if (gameIdRef.current !== game.id || calculating) {
            return;
        }
        if (game.status === GameStatus.NOT_STARTED) {
            if (nowplayState.nowplayHandCursor !== -1 || nowplayState.nowplayMoveCursor !== -1) {
                setNowplayState({
                    nowplayHandCursor: -1,
                    nowplayMoveCursor: -1,
                    lastUpdateTime: Date.now(),
                });
            }
            return;
        }
        const playSoundForStateTransition = (prevState: DmPlayState | null, newState: DmPlayState) => {
            if (newState.is_hand_over && (prevState === null || !prevState.is_hand_over)) {
                playBigDrum();
            }

            const wasActiveNonEngine = prevState !== null && prevState.active_player === playerSeat && !prevState.is_engine_turn;
            const isActiveNonEngine = newState.active_player === playerSeat && !newState.is_engine_turn;

            if (isActiveNonEngine && !wasActiveNonEngine) {
                playDrum();
            } else {
                playPop();
            }
        };
        const prevState =
            nowplayState.nowplayHandCursor === -1 ||
            nowplayState.nowplayMoveCursor === -1 ||
            nowplayState.nowplayHandCursor >= game.game_hands.length ||
            nowplayState.nowplayMoveCursor >= (getHandHistory(game, nowplayState.nowplayHandCursor, handHistoryCache) ?? []).length
                ? null
                : getHandHistory(game, nowplayState.nowplayHandCursor, handHistoryCache)![nowplayState.nowplayMoveCursor].state;
        const maxHandCursor = game.game_hands.length - 1;
        const maxMoveCursor = (game.game_hands[maxHandCursor]?.history?.length ?? 0) - 1;

        const advanceState = (mute: boolean = false) => {
            setNowplayState((prevState) => ({
                ...prevState,
                nowplayMoveCursor: prevState.nowplayMoveCursor + 1,
                lastUpdateTime: Date.now(),
            }));
            const newState = game.game_hands[nowplayState.nowplayHandCursor].history![nowplayState.nowplayMoveCursor + 1].state;
            if (!mute) {
                playSoundForStateTransition(prevState, newState);
            }
        };

        if (nowplayState.nowplayHandCursor !== maxHandCursor) {
            // If we're not on the latest hand, jump to the end of the latest hand
            setNowplayState({
                nowplayHandCursor: maxHandCursor,
                nowplayMoveCursor: maxMoveCursor,
                lastUpdateTime: Date.now(),
            });
            console.log(nowplayState.nowplayMoveCursor + 1 + " Jumping to latest hand");
            const newState = game.game_hands[maxHandCursor].history![maxMoveCursor].state;
            playSoundForStateTransition(prevState, newState);
        } else if (nowplayState.nowplayMoveCursor < maxMoveCursor) {
            // If we're on the right hand but behind, advance one move at a time
            const wasActiveNonEngine = prevState !== null && prevState.active_player === playerSeat && !prevState.is_engine_turn;
            if (wasActiveNonEngine) {
                advanceState(true);
            } else if (Date.now() - nowplayState.lastUpdateTime > nowplayStepDelay) {
                advanceState();
            } else {
                if (nowplayAdvanceTimeoutRef.current) {
                    clearTimeout(nowplayAdvanceTimeoutRef.current);
                }

                nowplayAdvanceTimeoutRef.current = setTimeout(() => {
                    advanceState();
                    nowplayAdvanceTimeoutRef.current = null;
                }, nowplayStepDelay - (Date.now() - nowplayState.lastUpdateTime));
            }
        } else if (nowplayState.nowplayMoveCursor > maxMoveCursor) {
            // If we're ahead of the latest move, jump to the latest move
            console.log(maxMoveCursor + " Jumping backward to latest move");
            setNowplayState({
                ...nowplayState,
                nowplayMoveCursor: maxMoveCursor,
                lastUpdateTime: Date.now(),
            });
            const newState = game.game_hands[nowplayState.nowplayHandCursor].history![maxMoveCursor].state;
            playSoundForStateTransition(prevState, newState);
        }

        // Cleanup function to clear the timeout when the component unmounts or dependencies change
        return () => {
            if (nowplayAdvanceTimeoutRef.current) {
                clearTimeout(nowplayAdvanceTimeoutRef.current);
            }
        };
    }, [game, nowplayState, playerSeat, handHistoryCache, nowplayStepDelay, calculating]);

    const value = useMemo(() => {
        if (game.game_hands.length === 0 || loading || gameIdRef.current !== game.id || calculating) {
            return {
                table,
                game,
                loading: loading || gameIdRef.current !== game.id || calculating,
                replayState,
                setReplayState,
                nowplayState,
                setNowplayState,
                playerSeat,
                handHistoryCache,
            };
        }
        const displayedGameHandIndex = replayState.replayModeEnabled ? replayState.replayHandCursor : nowplayState.nowplayHandCursor;
        const displayedHistoryIndex = replayState.replayModeEnabled ? replayState.replayMoveCursor : nowplayState.nowplayMoveCursor;
        const fullDisplayedHandHistory = getHandHistory(game, displayedGameHandIndex, handHistoryCache);
        if (fullDisplayedHandHistory === null) {
            return {
                table,
                game,
                loading,
                replayState,
                setReplayState,
                nowplayState,
                setNowplayState,
                playerSeat,
                handHistoryCache,
            };
        }
        return {
            table,
            game,
            loading,
            replayState,
            setReplayState,
            nowplayState,
            setNowplayState,
            playerSeat,
            handHistoryCache,
            gameplayContext: {
                displayedGameHandIndex,
                maxDisplayedGameHandIndex: game.game_hands.length - 1,
                displayedGameHand: game.game_hands[displayedGameHandIndex],

                displayedHistoryIndex,
                maxDisplayedHistoryIndex: replayState.replayModeEnabled
                    ? fullDisplayedHandHistory.length - 1
                    : nowplayState.nowplayMoveCursor,
                displayedPlayState: fullDisplayedHandHistory[displayedHistoryIndex].state,

                fullDisplayedHandHistory,
                truncatedHandHistory: fullDisplayedHandHistory.slice(0, displayedHistoryIndex + 1),
            },
        };
    }, [table, game, replayState, nowplayState, handHistoryCache, loading, playerSeat, calculating]);

    return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}

export function useTableContext() {
    const context = useContext(TableContext);
    if (!context) {
        throw new Error("useTableContext must be used within a TableContextProvider");
    }
    return context;
}
