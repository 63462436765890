import styled from "styled-components";

export const TooltipWrapper = styled.div`
    position: relative;
    display: inline-flex;

    &:hover > div {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.5s;
    }
`;

export const TooltipContent = styled.div`
    visibility: hidden;
    position: absolute;
    top: 80%;
    left: 90%;
    padding: 8px 8px;
    background-color: ${(props) => props.theme.colors.tooltip_background};
    color: white;
    border-radius: 3px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s, visibility 0s linear 0s;
    transition-delay: 0s;
`;

interface TooltipProps {
    content: string;
    children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    return (
        <TooltipWrapper>
            {children}
            <TooltipContent>{content}</TooltipContent>
        </TooltipWrapper>
    );
};
