import { CardId, PosId } from "../client/basic-types";
import { DmPlayState } from "../client/server-types-python";

export enum MarkedCardType {
    NONE = 0,
    FROM_LEFT = 1,
    FROM_CENTER = 2,
    FROM_RIGHT = 3,
}

export enum MarkTeamSource {
    TEAM_BLUE = 0,
    TEAM_RED = 1,
}

export interface MarkedCardInfo {
    type: MarkedCardType;
    teamSource: MarkTeamSource;
}

const getMarkedCardsFromAllPlayers = (playState: DmPlayState, perspectiveSeat: PosId) => {
    const result: Partial<Record<CardId, MarkedCardInfo>> = {};
    for (const pos of [0, 1, 2, 3]) {
        const ourTeam = pos % 2 === 0 ? MarkTeamSource.TEAM_BLUE : MarkTeamSource.TEAM_RED;
        const ourSlate = playState.positions[pos].trade_slate;
        if (ourSlate !== null) {
            const l = pos === (perspectiveSeat + 1) % 4 ? MarkedCardType.FROM_LEFT : MarkedCardType.FROM_RIGHT;
            const c = MarkedCardType.FROM_CENTER;
            const r = pos === (perspectiveSeat + 3) % 4 ? MarkedCardType.FROM_RIGHT : MarkedCardType.FROM_LEFT;
            result[ourSlate[0]] = { type: l, teamSource: ourTeam };
            result[ourSlate[1]] = { type: c, teamSource: ourTeam };
            result[ourSlate[2]] = { type: r, teamSource: ourTeam };
        }
    }
    return result;
};

export const getMarkedCards = (playState: DmPlayState, pos: PosId | -1, allHandsVisible: boolean) => {
    if (playState.is_trade_phase || playState.grand_phase_state !== null) {
        return {};
    }
    if (allHandsVisible) {
        return getMarkedCardsFromAllPlayers(playState, pos === -1 ? 0 : pos);
    }
    if (pos === -1) {
        return {};
    }
    const result: Partial<Record<CardId, MarkedCardInfo>> = {};
    const ourSlate = playState.positions[pos].trade_slate;
    const leftSlate = playState.positions[(pos + 1) % 4].trade_slate;
    const centerSlate = playState.positions[(pos + 2) % 4].trade_slate;
    const rightSlate = playState.positions[(pos + 3) % 4].trade_slate;

    if (ourSlate !== null && leftSlate !== null && centerSlate !== null && rightSlate !== null) {
        const ourTeam = pos % 2 === 0 ? MarkTeamSource.TEAM_BLUE : MarkTeamSource.TEAM_RED;
        const otherTeam = ourTeam === MarkTeamSource.TEAM_BLUE ? MarkTeamSource.TEAM_RED : MarkTeamSource.TEAM_BLUE;
        result[ourSlate[0]] = { type: MarkedCardType.FROM_RIGHT, teamSource: ourTeam };
        result[ourSlate[1]] = { type: MarkedCardType.FROM_CENTER, teamSource: ourTeam };
        result[ourSlate[2]] = { type: MarkedCardType.FROM_LEFT, teamSource: ourTeam };
        result[leftSlate[2]] = { type: MarkedCardType.FROM_LEFT, teamSource: otherTeam };
        result[centerSlate[1]] = { type: MarkedCardType.FROM_CENTER, teamSource: ourTeam };
        result[rightSlate[0]] = { type: MarkedCardType.FROM_RIGHT, teamSource: otherTeam };
    }
    return result;
};
