import styled from "styled-components";
import { NavBar } from "../components/NavBar";

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 16px;
    padding: 30px 50px 30px 50px;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: center;
    align-content: center;
    text-align: left;
    margin: 8px;
`;

const Header = styled.h2`
    font-size: 24px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

const BigHeader = styled.h1`
    font-size: 36px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

export function AboutPage(): JSX.Element {
    return (
        <>
            <NavBar />

            <ParentDiv>
                <MainDiv>
                    <Section>
                        <BigHeader>About</BigHeader>
                    </Section>
                    <Section>
                        <p>
                            <b>tichuengine.com</b> is a hobby project by me, Sean McCarthy. I'm aiming to provide a smooth and convenient
                            way to play Tichu digitally. Features include one-click combination plays, game replays, unlimited undo,
                            optional duplicate scoring, and AI opponents.
                        </p>
                        <p>
                            Tichu is one of my favorite games and is best played in person with real cards. But sometimes, playing in person
                            is not an option.
                        </p>
                        <p>
                            This website is still in development, and is <b>very much a work in progress</b>. Right now, it is only publicly
                            accessible for testing purposes, so please don't advertise it or rely on it.
                        </p>
                    </Section>
                    <Section>
                        <Header>Rules differences from standard Tichu</Header>
                    </Section>
                    <Section>
                        <p>
                            The game available here has slightly tweaked rules compared to Tichu. This is due to my belief that certain
                            aspects of Tichu work well in person but translate clumsily to the digital realm: specifically, the actions that
                            you can take out of turn.
                        </p>
                        <p>
                            Here, bombing and calling Tichu out of turn are no longer possible. This allows gameplay to flow more smoothly
                            and without worrying about reflexes.
                        </p>
                        <p>
                            These changes make for a slightly simpler game, and I don't consider them to be gameplay improvements. I see
                            them as compromises to make the interface friendlier that I think are worth the cost in depth.
                        </p>
                    </Section>
                    <Section>
                        <Header>Credit</Header>
                    </Section>
                    <Section>
                        <p>
                            <a href="https://boardgamegeek.com/boardgame/215/tichu">Tichu</a> is a game by Urs Hostettler and{" "}
                            <a href="https://fatamorgana.ch/fatamorgana/tichu">Fata Morgana</a>, and it's made available here with their
                            permission.
                        </p>
                        <p>Site design and programming by Sean McCarthy.</p>
                        <p>
                            The user avatar images and some game icons are sourced from <a href="https://game-icons.net/">Game-icons.net</a>{" "}
                            and were created by various artists: Lorc, Delapouite, John Colburn, Felbrigg, John Redman, Carl Olsen, Sbed,
                            PriorBlue, Willdabeast, Viscious Speed, Lord Berandas, Irongamer, HeavenlyDog, Lucas, Faithtoken, Skoll, Andy
                            Meneely, Cathelineau, Kier Heyl, Aussiesim, Sparker, Zeromancer, Rihlsul, Quoting, Guard13007, DarkZaitzev,
                            SpencerDub, GeneralAce135, Zajkonur, Catsu, Starseeker, Pepijn Poolman, Pierre Leducq, and Caro Asercion.
                        </p>
                        <p>
                            A few other game icons were licensed from <a href="https://thenounproject.com/">thenounproject.com</a>.
                        </p>
                    </Section>
                    <Section>
                        <Header>Bots (AI Opponents)</Header>
                    </Section>
                    <Section>
                        <p>
                            The bots are a complex work in progress. Their underlying basis is a pair of neural networks that know how to
                            play face-up-tichu. The value net guesses the expected score of a given position if the hand were played out.
                            The policy net guesses which actions are most likely to be chosen in a given position. These two networks are
                            the scaffolding for a Monte Carlo Tree Search (MCTS) that tries to investigate promising lines and figure out
                            which one is best. (The policy net helps pick lines, and the value net helps evaluate the results.)
                        </p>
                        <p>
                            But of course, Tichu is played with hands concealed from other players (until there are only two players left,
                            at which point the bot knows all the cards). So to make their decisions, the bots generate 12 different random
                            permutations of possible hand distributions for the other players. Then they run a variation of MCTS that
                            averages the scores across the 12 possible worlds they are considering.
                        </p>
                        <p>
                            Grand tichu calls and card passing are handled separately, as follows:
                            <ul>
                                <li>
                                    The bots call grand when they have at least 16 "queen points" where a queen = 1, king = 2, ace = 4,
                                    phoenix or dragon or quad-bomb = 8. They never overcall grand.
                                </li>
                                <li>
                                    <b>The bots' opponent-passing convention</b> is Odd Left / Even Right. This means: among the two cards
                                    selected to be passed to opponents, send the smallest odd card left, and/or the smallest even card
                                    right. The goal is to minimize collisions where both of you pass the same card to the same opponent;
                                    it's assumed that 2 and 3 are the most common cards to pass to opponents.
                                </li>
                                <li>
                                    <b>The bots' partner pass convention</b> is what I use (Odd 2+ / Even 0): Count your "high cards"
                                    (A/Ph/Dr); with 2+ pass odd, with 0 pass even, with 1 pass that card; you may always pass the 1 or dog
                                    and no high card count is assumed by convention, but this is generally only done with a strong and/or
                                    fast hand; if one of us called grand, the convention is off and you use common sense; if your right-hand
                                    opponent called grand, you may pass odd with only one high card.
                                </li>
                                <li>
                                    The bots choose their passes using a hard-coded but computationally intensive strategy. They consider
                                    all 1092 possible combinations of 1 card to partner and 2 cards to the opponents. Each possibility
                                    scores for the weakness of cards passed to opponents, the strength of the card passed to partner, and
                                    whether the card passed to partner follows the convention accurately. Additionally, the remaining 11
                                    cards are evaluated repeatedly by combining them with - one at a time - the many different possible
                                    cards we could be passed, and scoring those 12 cards. The scores are then averaged, weighted by a guess
                                    of how likely opponents and partner are to pass that card to us.
                                </li>
                            </ul>
                        </p>
                        <p>
                            To train the neural networks, I use techniques similar to AlphaZero. I run many thousands of bot games using
                            face up cards and MCTS (assisted by the nets) to pick good moves. Data from those games trains the neural
                            networks. Now with stronger nets, we can repeat this process with MCTS that performs slightly better than the
                            previous time.
                        </p>
                        <p>
                            When generating possible hand distributions for the other players, the bots use three basic strategies that
                            improve on totally random generation.
                            <ul>
                                <li>They remember what they passed to other players.</li>
                                <li>
                                    They infer restrictions on the counts of high cards based on what their partner passed to them, as well
                                    as based on tichu calls.
                                </li>
                                <li>
                                    They generate more distributions initially, then rate each distribution of cards based on how "coherent"
                                    (full of cards that work well together, as opposed to singles) each player's hand is. The most coherent
                                    distributions are kept and the rest are thrown out. This mimics reality in that real players' hands
                                    generally become more coherent as the game progresses and they are able to shed singles, etc.
                                </li>
                            </ul>
                        </p>
                    </Section>
                </MainDiv>
            </ParentDiv>
        </>
    );
}
