import { createContext, Dispatch, SetStateAction } from "react";

export interface UiSettings {
    showBotPanel: boolean;
    revealPlayerHands: boolean;
}

export const defaultUiSettings: UiSettings = {
    showBotPanel: false,
    revealPlayerHands: false,
};

export const UiSettingsContext = createContext<{ uiSettings: UiSettings; setUiSettings: Dispatch<SetStateAction<UiSettings>> }>({
    uiSettings: defaultUiSettings,
    setUiSettings: () => {},
});
