import { ActionId } from "../client/basic-types";
import { CombinationType } from "../client/server-types-python";

export interface Action {
    id: ActionId;
    name: string;
    isBomb: boolean;
    rankCounts: number[]; // dog 1 2 3 4 5 6 7 8 9 T J Q K A D SinglePh
    combinationType: CombinationType;
    rank: number;
}

export const ACTIONS: Action[] = [
    {
        id: 0,
        name: "(pass)",
        isBomb: false,
        combinationType: CombinationType.PASS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },

    // Single special cards
    {
        id: 1,
        name: "Phoenix",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
        rank: 1,
    },
    {
        id: 2,
        name: "Dragon",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
        rank: 15,
    },
    {
        id: 3,
        name: "Dog",
        isBomb: false,
        combinationType: CombinationType.DOG,
        rankCounts: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },

    // Single cards
    {
        id: 4,
        name: "1",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 1,
    },
    {
        id: 5,
        name: "2",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 6,
        name: "3",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 7,
        name: "4",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 8,
        name: "5",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 9,
        name: "6",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 10,
        name: "7",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 11,
        name: "8",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 12,
        name: "9",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 13,
        name: "T",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 14,
        name: "J",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 15,
        name: "Q",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 16,
        name: "K",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 17,
        name: "A",
        isBomb: false,
        combinationType: CombinationType.SINGLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
        rank: 14,
    },

    // Pairs
    {
        id: 18,
        name: "22",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 19,
        name: "33",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 20,
        name: "44",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 21,
        name: "55",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 22,
        name: "66",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 23,
        name: "77",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 24,
        name: "88",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 25,
        name: "99",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 26,
        name: "TT",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 27,
        name: "JJ",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 28,
        name: "QQ",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 29,
        name: "KK",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 30,
        name: "AA",
        isBomb: false,
        combinationType: CombinationType.PAIR,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 14,
    },

    // Triples
    {
        id: 31,
        name: "222",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 32,
        name: "333",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 33,
        name: "444",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 34,
        name: "555",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 35,
        name: "666",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 36,
        name: "777",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 37,
        name: "888",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 38,
        name: "999",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 39,
        name: "TTT",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 40,
        name: "JJJ",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 41,
        name: "QQQ",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 42,
        name: "KKK",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 43,
        name: "AAA",
        isBomb: false,
        combinationType: CombinationType.TRIPLE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },

    // Bombs (quads)
    {
        id: 44,
        name: "2222",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 45,
        name: "3333",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 46,
        name: "4444",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 47,
        name: "5555",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 48,
        name: "6666",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 49,
        name: "7777",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 50,
        name: "8888",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 51,
        name: "9999",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 52,
        name: "TTTT",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 53,
        name: "JJJJ",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 54,
        name: "QQQQ",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 55,
        name: "KKKK",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0],
        rank: 13,
    },
    {
        id: 56,
        name: "AAAA",
        isBomb: true,
        combinationType: CombinationType.QUAD_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0],
        rank: 14,
    },

    // Full Houses with 2s as triple
    {
        id: 57,
        name: "22233",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 58,
        name: "22244",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 59,
        name: "22255",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 60,
        name: "22266",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 61,
        name: "22277",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 62,
        name: "22288",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 63,
        name: "22299",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 64,
        name: "222TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 65,
        name: "222JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 66,
        name: "222QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 2,
    },
    {
        id: 67,
        name: "222KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 2,
    },
    {
        id: 68,
        name: "222AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 2,
    },

    // Full Houses with 3s as triple
    {
        id: 69,
        name: "33322",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 70,
        name: "33344",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 71,
        name: "33355",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 72,
        name: "33366",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 73,
        name: "33377",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 74,
        name: "33388",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 75,
        name: "33399",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 76,
        name: "333TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 77,
        name: "333JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 78,
        name: "333QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 79,
        name: "333KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 3,
    },
    {
        id: 80,
        name: "333AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 3,
    },

    // Full Houses with 4s as triple
    {
        id: 81,
        name: "44422",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 82,
        name: "44433",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 83,
        name: "44455",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 84,
        name: "44466",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 85,
        name: "44477",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 86,
        name: "44488",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 87,
        name: "44499",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 88,
        name: "444TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 89,
        name: "444JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 90,
        name: "444QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 91,
        name: "444KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 4,
    },
    {
        id: 92,
        name: "444AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 4,
    },

    // Full Houses with 5s as triple
    {
        id: 93,
        name: "55522",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 94,
        name: "55533",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 95,
        name: "55544",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 96,
        name: "55566",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 97,
        name: "55577",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 98,
        name: "55588",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 99,
        name: "55599",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 100,
        name: "555TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 101,
        name: "555JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 102,
        name: "555QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 103,
        name: "555KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 5,
    },
    {
        id: 104,
        name: "555AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 5,
    },

    // Full Houses with 6s as triple
    {
        id: 105,
        name: "66622",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 106,
        name: "66633",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 107,
        name: "66644",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 108,
        name: "66655",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 109,
        name: "66677",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 110,
        name: "66688",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 111,
        name: "66699",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 112,
        name: "666TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 113,
        name: "666JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 114,
        name: "666QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 115,
        name: "666KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 6,
    },
    {
        id: 116,
        name: "666AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 6,
    },

    // Full Houses with 7s as triple
    {
        id: 117,
        name: "77722",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 118,
        name: "77733",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 119,
        name: "77744",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 120,
        name: "77755",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 121,
        name: "77766",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 122,
        name: "77788",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 123,
        name: "77799",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 124,
        name: "777TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 125,
        name: "777JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 126,
        name: "777QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 127,
        name: "777KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 7,
    },
    {
        id: 128,
        name: "777AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 7,
    },

    // Full Houses with 8s as triple
    {
        id: 129,
        name: "88822",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 130,
        name: "88833",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 131,
        name: "88844",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 132,
        name: "88855",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 133,
        name: "88866",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 134,
        name: "88877",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 135,
        name: "88899",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 136,
        name: "888TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 137,
        name: "888JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 138,
        name: "888QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 139,
        name: "888KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0, 0],
        rank: 8,
    },
    {
        id: 140,
        name: "888AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0],
        rank: 8,
    },

    // Full Houses with 9s as triple
    {
        id: 141,
        name: "99922",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 142,
        name: "99933",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 143,
        name: "99944",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 144,
        name: "99955",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 145,
        name: "99966",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 146,
        name: "99977",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 147,
        name: "99988",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 148,
        name: "999TT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 149,
        name: "999JJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 150,
        name: "999QQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 151,
        name: "999KK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0],
        rank: 9,
    },
    {
        id: 152,
        name: "999AA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 2, 0, 0],
        rank: 9,
    },

    // Full Houses with 10s as triple
    {
        id: 153,
        name: "TTT22",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 154,
        name: "TTT33",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 155,
        name: "TTT44",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 156,
        name: "TTT55",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 157,
        name: "TTT66",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 158,
        name: "TTT77",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 159,
        name: "TTT88",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 160,
        name: "TTT99",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 161,
        name: "TTTJJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 162,
        name: "TTTQQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 163,
        name: "TTTKK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 0],
        rank: 10,
    },
    {
        id: 164,
        name: "TTTAA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0],
        rank: 10,
    },

    // Full Houses with Jacks as triple
    {
        id: 165,
        name: "JJJ22",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 166,
        name: "JJJ33",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 167,
        name: "JJJ44",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 168,
        name: "JJJ55",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 169,
        name: "JJJ66",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 170,
        name: "JJJ77",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 171,
        name: "JJJ88",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 172,
        name: "JJJ99",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 173,
        name: "JJJTT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 174,
        name: "JJJQQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 175,
        name: "JJJKK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 0],
        rank: 11,
    },
    {
        id: 176,
        name: "JJJAA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0],
        rank: 11,
    },

    // Full Houses with Queens as triple
    {
        id: 177,
        name: "QQQ22",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 178,
        name: "QQQ33",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 179,
        name: "QQQ44",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 180,
        name: "QQQ55",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 181,
        name: "QQQ66",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 182,
        name: "QQQ77",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 183,
        name: "QQQ88",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 184,
        name: "QQQ99",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 185,
        name: "QQQTT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 186,
        name: "QQQJJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 187,
        name: "QQQKK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0],
        rank: 12,
    },
    {
        id: 188,
        name: "QQQAA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0],
        rank: 12,
    },

    // Full Houses with Kings as triple
    {
        id: 189,
        name: "KKK22",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 190,
        name: "KKK33",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 191,
        name: "KKK44",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 192,
        name: "KKK55",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 193,
        name: "KKK66",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 194,
        name: "KKK77",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 195,
        name: "KKK88",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 196,
        name: "KKK99",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 197,
        name: "KKKTT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 198,
        name: "KKKJJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 199,
        name: "KKKQQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0],
        rank: 13,
    },
    {
        id: 200,
        name: "KKKAA",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0],
        rank: 13,
    },

    // Full Houses with Aces as triple
    {
        id: 201,
        name: "AAA22",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 202,
        name: "AAA33",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 203,
        name: "AAA44",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 204,
        name: "AAA55",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 205,
        name: "AAA66",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 206,
        name: "AAA77",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 207,
        name: "AAA88",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 208,
        name: "AAA99",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 209,
        name: "AAATT",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 210,
        name: "AAAJJ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 211,
        name: "AAAQQ",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 3, 0, 0],
        rank: 14,
    },
    {
        id: 212,
        name: "AAAKK",
        isBomb: false,
        combinationType: CombinationType.FULL_HOUSE,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0],
        rank: 14,
    },

    // 2-card stairs (pairs of consecutive ranks)
    {
        id: 213,
        name: "2233",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 3,
    },
    {
        id: 214,
        name: "3344",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 215,
        name: "4455",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 216,
        name: "5566",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 217,
        name: "6677",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 218,
        name: "7788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 219,
        name: "8899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 220,
        name: "99TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 221,
        name: "TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 222,
        name: "JJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 223,
        name: "QQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 224,
        name: "KKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0],
        rank: 14,
    },

    // 3-card stairs (three consecutive pairs)
    {
        id: 225,
        name: "223344",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 4,
    },
    {
        id: 226,
        name: "334455",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 227,
        name: "445566",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 228,
        name: "556677",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 229,
        name: "667788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 230,
        name: "778899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 231,
        name: "8899TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 232,
        name: "99TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 233,
        name: "TTJJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 234,
        name: "JJQQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 235,
        name: "QQKKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0],
        rank: 14,
    },

    // 4-card stairs (four consecutive pairs)
    {
        id: 236,
        name: "22334455",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 237,
        name: "33445566",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 238,
        name: "44556677",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 239,
        name: "55667788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 240,
        name: "66778899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 241,
        name: "778899TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 242,
        name: "8899TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 243,
        name: "99TTJJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 244,
        name: "TTJJQQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 245,
        name: "JJQQKKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 0, 0],
        rank: 14,
    },

    // 5-card stairs (five consecutive pairs)
    {
        id: 246,
        name: "2233445566",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 247,
        name: "3344556677",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 248,
        name: "4455667788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 249,
        name: "5566778899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 250,
        name: "66778899TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 251,
        name: "778899TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 252,
        name: "8899TTJJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 253,
        name: "99TTJJQQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 254,
        name: "TTJJQQKKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 0],
        rank: 14,
    },

    // 6-card stairs (six consecutive pairs)
    {
        id: 255,
        name: "223344556677",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 256,
        name: "334455667788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 257,
        name: "445566778899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 258,
        name: "5566778899TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 259,
        name: "66778899TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 260,
        name: "778899TTJJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 261,
        name: "8899TTJJQQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 262,
        name: "99TTJJQQKKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        rank: 14,
    },

    // 7-card stairs (seven consecutive pairs)
    {
        id: 263,
        name: "22334455667788",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 264,
        name: "33445566778899",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 265,
        name: "445566778899TT",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 266,
        name: "5566778899TTJJ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 267,
        name: "66778899TTJJQQ",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 268,
        name: "778899TTJJQQKK",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0],
        rank: 13,
    },
    {
        id: 269,
        name: "8899TTJJQQKKAA",
        isBomb: false,
        combinationType: CombinationType.STAIRS,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0],
        rank: 14,
    },

    // 5-card straights
    {
        id: 270,
        name: "12345",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 5,
    },
    {
        id: 271,
        name: "23456",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 272,
        name: "34567",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 273,
        name: "45678",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 274,
        name: "56789",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 275,
        name: "6789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 276,
        name: "789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 277,
        name: "89TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 278,
        name: "9TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 279,
        name: "TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 6-card straights
    {
        id: 280,
        name: "123456",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 6,
    },
    {
        id: 281,
        name: "234567",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 282,
        name: "345678",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 283,
        name: "456789",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 284,
        name: "56789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 285,
        name: "6789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 286,
        name: "789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 287,
        name: "89TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 288,
        name: "9TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 7-card straights
    {
        id: 289,
        name: "1234567",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 7,
    },
    {
        id: 290,
        name: "2345678",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 291,
        name: "3456789",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 292,
        name: "456789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 293,
        name: "56789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 294,
        name: "6789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 295,
        name: "789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 296,
        name: "89TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 8-card straights
    {
        id: 297,
        name: "12345678",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 8,
    },
    {
        id: 298,
        name: "23456789",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 299,
        name: "3456789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 300,
        name: "456789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 301,
        name: "56789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 302,
        name: "6789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 303,
        name: "789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 9-card straights
    {
        id: 304,
        name: "123456789",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        rank: 9,
    },
    {
        id: 305,
        name: "23456789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 306,
        name: "3456789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 307,
        name: "456789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 308,
        name: "56789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 309,
        name: "6789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 10-card straights
    {
        id: 310,
        name: "123456789T",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        rank: 10,
    },
    {
        id: 311,
        name: "23456789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 312,
        name: "3456789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 313,
        name: "456789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 314,
        name: "56789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 11-card straights
    {
        id: 315,
        name: "123456789TJ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        rank: 11,
    },
    {
        id: 316,
        name: "23456789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 317,
        name: "3456789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 318,
        name: "456789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 12-card straights
    {
        id: 319,
        name: "123456789TJQ",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
        rank: 12,
    },
    {
        id: 320,
        name: "23456789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 321,
        name: "3456789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 13-card straights
    {
        id: 322,
        name: "123456789TJQK",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
        rank: 13,
    },
    {
        id: 323,
        name: "23456789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // 14-card straight
    {
        id: 324,
        name: "123456789TJQKA",
        isBomb: false,
        combinationType: CombinationType.STRAIGHT,
        rankCounts: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        rank: 14,
    },

    // SF Bomb
    {
        id: 325,
        name: "SF Bomb",
        isBomb: true,
        combinationType: CombinationType.STRAIGHT_FLUSH_BOMB,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },

    // Dragon gift actions
    {
        id: 326,
        name: "Dragon: left",
        isBomb: false,
        combinationType: CombinationType.GIVE_DRAGON,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 327,
        name: "Dragon: right",
        isBomb: false,
        combinationType: CombinationType.GIVE_DRAGON,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },

    // Wish actions
    {
        id: 328,
        name: "Wish: 2",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 329,
        name: "Wish: 3",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 330,
        name: "Wish: 4",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 331,
        name: "Wish: 5",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 332,
        name: "Wish: 6",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 333,
        name: "Wish: 7",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 334,
        name: "Wish: 8",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 335,
        name: "Wish: 9",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 336,
        name: "Wish: T",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 337,
        name: "Wish: J",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 338,
        name: "Wish: Q",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 339,
        name: "Wish: K",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
    {
        id: 340,
        name: "Wish: A",
        isBomb: false,
        combinationType: CombinationType.MAKE_WISH,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },

    // Tichu call
    {
        id: 341,
        name: "Tichu!",
        isBomb: false,
        combinationType: CombinationType.BET,
        rankCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rank: 0,
    },
];

export const SINGLES_ACTION_IDS_WEAK_TO_STRONG: ActionId[] = [4, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 2];

const PAIR_ACTION_IDS_WEAK_TO_STRONG = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const TRIPLE_ACTION_IDS_WEAK_TO_STRONG = [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43];
const FULL_HOUSE_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 212 - 57 + 1 }, (_, i) => i + 57);
const TWO_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 224 - 213 + 1 }, (_, i) => i + 213);
const THREE_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 235 - 225 + 1 }, (_, i) => i + 225);
const FOUR_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 245 - 236 + 1 }, (_, i) => i + 236);
const FIVE_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 254 - 246 + 1 }, (_, i) => i + 246);
const SIX_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 262 - 255 + 1 }, (_, i) => i + 255);
const SEVEN_STAIR_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 269 - 263 + 1 }, (_, i) => i + 263);
const FIVE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 279 - 270 + 1 }, (_, i) => i + 270);
const SIX_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 288 - 280 + 1 }, (_, i) => i + 280);
const SEVEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 296 - 289 + 1 }, (_, i) => i + 289);
const EIGHT_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 303 - 297 + 1 }, (_, i) => i + 297);
const NINE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = Array.from({ length: 309 - 304 + 1 }, (_, i) => i + 304);
const TEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = [310, 311, 312, 313, 314];
const ELEVEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = [315, 316, 317, 318];
const TWELVE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = [319, 320, 321];
const THIRTEEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = [322, 323];
const FOURTEEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG = [324];

// Doesn't include singles or bombs. The rest are roughly in ascending order of size,
// with straights ranked below stairs and full houses.
export const MULTICARD_ACTION_IDS_WEAK_TO_STRONG: ActionId[] = [
    ...PAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...TRIPLE_ACTION_IDS_WEAK_TO_STRONG,
    ...TWO_STAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...FIVE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...FULL_HOUSE_ACTION_IDS_WEAK_TO_STRONG,
    ...SIX_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...THREE_STAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...SEVEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...EIGHT_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...FOUR_STAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...NINE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...TEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...FIVE_STAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...ELEVEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...TWELVE_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...SIX_STAIR_ACTION_IDS_WEAK_TO_STRONG,
    ...THIRTEEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...FOURTEEN_STRAIGHT_ACTION_IDS_WEAK_TO_STRONG,
    ...SEVEN_STAIR_ACTION_IDS_WEAK_TO_STRONG,
] as ActionId[];

export const QUAD_BOMB_ACTION_IDS_WEAK_TO_STRONG: ActionId[] = [44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56];

export const STRAIGHT_FLUSH_BOMB_ACTION_IDs: ActionId[] = [325];
export const SINGLE_PHOENIX_ACTION_IDS: ActionId[] = [1];

export const ACTION_IDS_LOOKUP: ({ list: ActionId[]; index: number } | null)[] = [];
for (let i = 0; i < ACTIONS.length; i++) {
    ACTION_IDS_LOOKUP.push(null);
}
for (let i = 0; i < SINGLES_ACTION_IDS_WEAK_TO_STRONG.length; i++) {
    ACTION_IDS_LOOKUP[SINGLES_ACTION_IDS_WEAK_TO_STRONG[i]] = { list: SINGLES_ACTION_IDS_WEAK_TO_STRONG, index: i };
}
for (let i = 0; i < MULTICARD_ACTION_IDS_WEAK_TO_STRONG.length; i++) {
    ACTION_IDS_LOOKUP[MULTICARD_ACTION_IDS_WEAK_TO_STRONG[i]] = { list: MULTICARD_ACTION_IDS_WEAK_TO_STRONG, index: i };
}
for (let i = 0; i < QUAD_BOMB_ACTION_IDS_WEAK_TO_STRONG.length; i++) {
    ACTION_IDS_LOOKUP[QUAD_BOMB_ACTION_IDS_WEAK_TO_STRONG[i]] = { list: QUAD_BOMB_ACTION_IDS_WEAK_TO_STRONG, index: i };
}
for (let i = 0; i < STRAIGHT_FLUSH_BOMB_ACTION_IDs.length; i++) {
    ACTION_IDS_LOOKUP[STRAIGHT_FLUSH_BOMB_ACTION_IDs[i]] = { list: STRAIGHT_FLUSH_BOMB_ACTION_IDs, index: i };
}
for (let i = 0; i < SINGLE_PHOENIX_ACTION_IDS.length; i++) {
    ACTION_IDS_LOOKUP[SINGLE_PHOENIX_ACTION_IDS[i]] = { list: SINGLE_PHOENIX_ACTION_IDS, index: i };
}
