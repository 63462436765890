import { DmGameParticipant } from "../client/server-types-python";

export const NUM_AVATARS = 187;
export const RELATIVE_PRIME = 37;

export const getRandomAvatarId = () => {
    return Math.floor(Math.random() * NUM_AVATARS);
};

export const getParticipantAvatarUrl = (participant: DmGameParticipant | null) => {
    if (participant === null) {
        return null;
    }
    if (participant.user === null) {
        return getBotAvatarUrl("");
    }
    return getAvatarUrl("", participant.user.avatar);
};

export const getAvatarUrl = (baseUrl: string, avatar: number) => {
    if (avatar === undefined) {
        return `${baseUrl}/avatars/av_${"0".padStart(3, "0")}.svg`;
    }
    return `${baseUrl}/avatars/av_${avatar.toString().padStart(3, "0")}.svg`;
};

export const getBotAvatarUrl = (baseUrl: string) => {
    return `${baseUrl}/avatars/av_bot.svg`;
};

export const getNextAvatarId = (currentAvatarId: number) => {
    return (currentAvatarId + RELATIVE_PRIME) % NUM_AVATARS;
};

export const getPreviousAvatarId = (currentAvatarId: number) => {
    return (currentAvatarId - RELATIVE_PRIME + NUM_AVATARS) % NUM_AVATARS;
};
