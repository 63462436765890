import { DmGameParticipant, DmTable, GameMode } from "./server-types-python";

export const prependTableToTablesState = (tablesState: DmTable[], table: DmTable) => {
    if (tablesState.find((t) => t.id === table.id)) {
        return tablesState;
    }
    return [table].concat(tablesState);
};

export const getName = (participant: DmGameParticipant | null) => {
    if (!participant) {
        return "---";
    }
    return participant.bot_name ?? participant.user?.display_name ?? `? (${participant.seat})`;
};

export const getUserSettingSpeedLabel = (speed: 0 | 1 | 2 | 3 | 4) => {
    switch (speed) {
        case 0:
            return "Slowest";
        case 1:
            return "Slower";
        case 2:
            return "Normal";
        case 3:
            return "Faster";
        case 4:
            return "Fastest";
    }
};

export const getUserSettingVolumeLabel = (volume: 0 | 1 | 2 | 3 | 4) => {
    switch (volume) {
        case 0:
            return "Muted";
        case 1:
            return "Low";
        case 2:
            return "Medium";
        case 3:
            return "High";
        case 4:
            return "Max";
    }
};

export const getGameModeLabel = (gameMode: GameMode) => {
    switch (gameMode) {
        case GameMode.STANDARD:
            return "Standard (1000 points)";
        case GameMode.SHORT_600:
            return "Short (600 points)";
        case GameMode.ONE_HAND:
            return "Single Hand";
        case GameMode.FIXED_8:
            return "8 Hands";
        case GameMode.DUPLICATE:
            return "Duplicate";
    }
    return "Unknown Game Mode";
};
