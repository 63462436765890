import styled from "styled-components";
import { GameStatus } from "../../client/server-types-python";
import React from "react";
import { useTableContext } from "../TableContext";
import { getAvatarUrl, getBotAvatarUrl } from "../../misc/Avatars";
import { Tooltip } from "../common/Tooltip";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
`;

export const ModalContent = styled.div`
    position: relative;
    width: 400px;
    min-height: 100px;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.score_modal};
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    padding: 10px;
    margin: -10px;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
`;

export const ScoreTableScrollContainer = styled.div`
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const ScoreTable = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
`;

export const ScoreTableRow = styled.tr`
    background-color: ${(props) => props.theme.colors.white_text};
`;

export const ScoreTableHeader = styled.th<{ $isNs?: boolean }>`
    text-align: center;
    background-color: ${(props) =>
        props.$isNs === undefined
            ? props.theme.colors.primary_light
            : props.$isNs
            ? props.theme.colors.ns_color
            : props.theme.colors.ew_color};
    color: white;
    font-size: 16px;
    padding: 10px;
    width: 60px;

    img {
        margin-right: 4px;
        margin-left: 4px;
    }
`;

export const ScoreTableHeaderHandNumber = styled(ScoreTableHeader)`
    width: 30px;
`;

export const ScoreTableCell = styled.td<{ $isNs?: boolean }>`
    text-align: right;
    width: 60px;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.colors.primary_light};
    border-left: none;
    border-right: none;
    border-top: none;
    background-color: ${(props) =>
        props.$isNs === undefined
            ? props.theme.colors.primary_hinted_white
            : props.$isNs
            ? props.theme.colors.ns_color_light
            : props.theme.colors.ew_color_light};
`;

export const ScoreTableBottomRowCell = styled(ScoreTableCell)`
    border-bottom: none;
    font-weight: bold;
`;

export const ScoreTableBottomRowHandNumberCell = styled(ScoreTableBottomRowCell)`
    width: 30px;
`;

export const DoubleScoreTableCell = styled(ScoreTableCell)`
    text-align: center;
`;

export const HandNumberCell = styled(ScoreTableCell)`
    width: 30px;
`;

export const ReplayButtonCell = styled(ScoreTableCell)`
    text-align: center;
`;

export const ReplayHandButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    border: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 3px;
    cursor: pointer;
    font-size: 24px;
    border-radius: 6px;
    width: 36px;
    height: 36px;
`;

export type ScoreModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    nextGameFunc: () => void;
};

// TODO: Add ability to use replay state to show the score status of the past.
export const ScoreModal = ({ isOpen, setIsOpen, nextGameFunc }: ScoreModalProps): JSX.Element => {
    const tableContext = useTableContext();
    const table = tableContext.table;
    const game = tableContext.game;
    const setReplayState = tableContext.setReplayState;
    const gameIsOver = tableContext.game.status === GameStatus.COMPLETE;

    const avatars = game.participants.map((player) => (player?.user?.avatar ? getAvatarUrl("", player.user.avatar) : getBotAvatarUrl("")));
    const names = game.participants.map((player) => player?.user?.display_name ?? player?.bot_name ?? "Bot");

    if (!isOpen || !table.game) {
        return <></>;
    }
    const handEntries = [];
    let nsTotalScore = 0;
    let ewTotalScore = 0;
    for (const hand of table.game?.game_hands ?? []) {
        const playState = hand.play_state;
        if (playState.is_hand_over) {
            nsTotalScore += playState.ns_score;
            ewTotalScore += playState.ew_score;
            handEntries.push(
                <ScoreTableRow key={hand.index}>
                    <HandNumberCell>#{hand.index + 1}</HandNumberCell>
                    <ScoreTableCell $isNs={true}>{playState.ns_score}</ScoreTableCell>
                    <ScoreTableCell $isNs={false}>{playState.ew_score}</ScoreTableCell>
                    <ReplayButtonCell>
                        <ReplayHandButton
                            onClick={() => {
                                setReplayState({ replayModeEnabled: true, replayHandCursor: hand.index, replayMoveCursor: 0 });
                                setIsOpen(false);
                            }}
                        >
                            👁
                        </ReplayHandButton>
                    </ReplayButtonCell>
                </ScoreTableRow>
            );
        } else {
            handEntries.push(
                <ScoreTableRow key={hand.index}>
                    <HandNumberCell>#{hand.index + 1}</HandNumberCell>
                    <DoubleScoreTableCell colSpan={2} $isNs={undefined}>
                        In progress...
                    </DoubleScoreTableCell>
                    <ReplayButtonCell>
                        <ReplayHandButton
                            onClick={() => {
                                setReplayState({ replayModeEnabled: false, replayHandCursor: hand.index, replayMoveCursor: 0 });
                                setIsOpen(false);
                            }}
                        >
                            ⇨
                        </ReplayHandButton>
                    </ReplayButtonCell>
                </ScoreTableRow>
            );
        }
    }
    return (
        <ModalOverlay onClick={() => setIsOpen(false)}>
            <ModalContent
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
            >
                <CloseButton onClick={() => setIsOpen(false)}>X</CloseButton>
                <ScoreTableScrollContainer>
                    <ScoreTable>
                        <tbody>
                            <ScoreTableRow>
                                <ScoreTableHeaderHandNumber>Hand</ScoreTableHeaderHandNumber>
                                <ScoreTableHeader $isNs={true}>
                                    <Tooltip content={names[0]}>
                                        <img src={avatars[0]} alt={names[0]} width="30" height="30" />
                                    </Tooltip>
                                    <Tooltip content={names[2]}>
                                        <img src={avatars[2]} alt={names[2]} width="30" height="30" />
                                    </Tooltip>
                                </ScoreTableHeader>
                                <ScoreTableHeader $isNs={false}>
                                    <Tooltip content={names[1]}>
                                        <img src={avatars[1]} alt={names[1]} width="30" height="30" />
                                    </Tooltip>
                                    <Tooltip content={names[3]}>
                                        <img src={avatars[3]} alt={names[3]} width="30" height="30" />
                                    </Tooltip>
                                </ScoreTableHeader>
                                <ScoreTableHeader>Replay</ScoreTableHeader>
                            </ScoreTableRow>
                            {handEntries}
                            <ScoreTableRow>
                                <ScoreTableBottomRowHandNumberCell>Total</ScoreTableBottomRowHandNumberCell>
                                <ScoreTableBottomRowCell $isNs={true}>{nsTotalScore}</ScoreTableBottomRowCell>
                                <ScoreTableBottomRowCell $isNs={false}>{ewTotalScore}</ScoreTableBottomRowCell>
                                <ScoreTableBottomRowCell></ScoreTableBottomRowCell>
                            </ScoreTableRow>
                        </tbody>
                    </ScoreTable>
                </ScoreTableScrollContainer>
                {gameIsOver && (
                    <button className="nextHandButton easyButton" onClick={nextGameFunc}>
                        Next Game
                    </button>
                )}
            </ModalContent>
        </ModalOverlay>
    );
};
