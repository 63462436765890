import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { WebSocketClient } from "../client/WebSocketClient";
import { ApiClient } from "../client/ApiClient";
import { MoveUtil } from "../model/MoveUtil";
import { Card, RANKS } from "./game/Card";
import { CardDetails } from "../model/CardDetails";
import { Hand } from "./game/Hand";
import { Alignment, Seat } from "./game/Seat";
import { SeatActivePlay } from "./game/SeatActivePlay";
import { Pass } from "./game/Pass";
import {
    CombinationType,
    DmTradeSlateChoice,
    DmCombination,
    GameStatus,
    DmTableSettings,
    DmGameEvent,
    DmTableEvent,
    DmTableEventType,
    DmPlayStatePhase,
    DmGameEventType,
    DmGame,
} from "../client/server-types-python";
import { ACTION_ID_BET, ACTION_ID_PASS, CardId, Rank } from "../client/basic-types";
import { GameLayout } from "./GameLayout";
import { TrickInfo } from "./game/TrickInfo";
import { HistoryPanel } from "./game/HistoryPanel";
import { ReplayState } from "../model/ReplayState";
import styled from "styled-components";
import { TradeCardSlot } from "./TradeCardSlot";
import { LoadingDiv } from "./LoadingDiv";
import { playDrum, playPop } from "../misc/SoundPlayer";
import { GameRequest } from "./GameRequest";
import { LayoutGroup, motion } from "framer-motion";
import { TableSetup } from "./TableSetup";
import { ReplayBar } from "./game/ReplayBar";
import { AiAssessmentPanel } from "./game/AiAssessmentPanel";
import { ConvenienceOptionsPanel } from "./game/ConvenienceOptionsPanel";
import { TableContext } from "./TableContext";
import { UserContext } from "../contexts/UserContext";
import { PlayControlsBar } from "./game/PlayControlsBar";
import { ActionMoveAssistant } from "../model/ActionMoveAssistant";
import { ScoreModal } from "./game/ScoreModal";
import { UiSettingsContext } from "../model/UiSettings";
import { useNavigate } from "react-router-dom";
import { getMarkedCards } from "../model/MarkedCardsUtil";

export type TableProps = {
    apiClient: ApiClient;
    webSocketClient: WebSocketClient | null;
    setGame: (game: DmGame, tableId: string) => void;
    joinTable: (tableId: string, seat?: number) => void;
};

const TradeArea = styled.div`
    width: 160px;
    height: 60px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px;
`;

const Controls = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const RequestAcceptButton = styled.button`
    background-color: ${(props) => props.theme.colors.action_positive};
    color: white;
    margin: 4px;
    border-radius: 8px;
    border: 1px solid darkgray;
    width: 60px;
    height: 30px;

    &:hover {
        background-color: ${(props) => props.theme.colors.action_positive_hover};
    }
`;

const RequestDeclineButton = styled.button`
    background-color: ${(props) => props.theme.colors.action_negative};
    color: white;
    margin: 4px;
    border-radius: 8px;
    border: 1px solid darkgray;
    width: 60px;
    height: 30px;

    &:hover {
        background-color: ${(props) => props.theme.colors.action_negative_hover};
    }
`;

const SidebarContainer = styled.div`
    height: 554px;
    width: 274px;
`;

export const Table = ({ apiClient, webSocketClient, setGame, joinTable }: TableProps): JSX.Element => {
    const tc = useContext(TableContext)!;
    const navigate = useNavigate();
    const table = tc.table;
    const game = tc.game;
    const playerSeat = tc.playerSeat;
    const user = useContext(UserContext)!.user;
    const { uiSettings } = useContext(UiSettingsContext)!;

    const [selectedCards, setSelectedCards] = useState<CardId[]>([]);
    // Cards that the user has asked to play, but we don't yet have confirmation from the server
    const [playLimboCards, setPlayLimboCards] = useState<CardId[]>([]);
    // Whether the user has submitted a move and is waiting for the server to confirm
    const [waitingOnSubmittedMove, setWaitingOnSubmittedMove] = useState(false);
    const [tradeSlateCards, setTradeSlateCards] = useState<(CardId | null)[]>([null, null, null]);
    const [tradeSlateDirty, setTradeSlateDirty] = useState(false);
    const [wishValue, setWishValue] = useState<Rank | undefined | "No wish">(undefined);
    const exitReplayMode = () => {
        tc.setReplayState({
            replayModeEnabled: false,
            replayHandCursor: 0,
            replayMoveCursor: 0,
        });
    };

    const [scoreModalOpen, setScoreModalOpen] = useState(false);

    const showScoreModal = () => {
        setScoreModalOpen(true);
    };

    const [suggestedPlays, setSuggestedPlays] = useState<DmCombination[]>([]);
    const [betChoice, setBetChoice] = useState(false);

    const [autoplayTimeoutEnd, setAutoplayTimeoutEnd] = useState<number | null>(null);
    const autoplayTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const clearAutoplayTimeout = () => {
        if (autoplayTimeoutRef.current) {
            clearTimeout(autoplayTimeoutRef.current);
            autoplayTimeoutRef.current = null;
        }
        setAutoplayTimeoutEnd(null);
    };

    const [autopassNoCards, setAutopassNoCards] = useState(() => {
        return localStorage.getItem("autopassNoCards") === "true";
    });
    const [autopassCantPlay, setAutopassCantPlay] = useState(() => {
        return localStorage.getItem("autopassCantPlay") === "true";
    });

    const onTableEvent = useCallback(
        (event: DmTableEvent | DmGameEvent) => {
            if (event.type === DmGameEventType.GAME_MOVE) {
                if (game === null) {
                    apiClient.getTable(table.id).then((table) => {
                        if (table instanceof Error) {
                            console.error(table);
                            return;
                        }
                        setGame(table.game, table.id);
                        console.log("table.game", table.game);
                    });
                } else {
                    const latestHand = game.game_hands[game.game_hands.length - 1];
                    if (latestHand.history === null || latestHand.history[latestHand.history.length - 1].index !== event.entry.index - 1) {
                        apiClient.getGameHand(table.id, latestHand.index).then((gameHand) => {
                            if (gameHand instanceof Error) {
                                console.error(gameHand);
                                return;
                            }
                            const newGame = {
                                ...game,
                                status: event.status,
                                game_hands: game.game_hands.map((hand) => (hand.index === gameHand.index ? gameHand : hand)),
                            };
                            setGame(newGame, table.id);
                        });
                    } else {
                        const newHistory = [...latestHand.history, event.entry];
                        const newHand = { ...latestHand, history: newHistory, play_state: event.entry.state };
                        const newGame = {
                            ...game,
                            status: event.status,
                            game_hands: game.game_hands.map((hand) => (hand.index === latestHand.index ? newHand : hand)),
                        };
                        setGame(newGame, table.id);
                    }
                }
            } else {
                setGame(event.game, table.id);
                // TODO: distinguish between different table events
            }
        },
        [apiClient, game, setGame, table.id]
    );

    // While we have a websocket client, subscribe to table events
    useEffect(() => {
        if (webSocketClient !== null) {
            if (table.id) {
                webSocketClient.subscribeToTableEvents(table.id, onTableEvent);
            } else {
                webSocketClient.unsubscribeFromTableEvents();
            }
        }
    }, [table.id, webSocketClient, onTableEvent]);

    // While we have a websocket client subscribe to event feed
    const setReplayState = tc.setReplayState;
    useEffect(() => {
        if (webSocketClient !== null) {
            const onTableEvent = (event: DmTableEvent | DmGameEvent) => {
                if (event.type === DmTableEventType.GAME_REWOUND) {
                    setReplayState((prevState: ReplayState) => ({
                        ...prevState,
                        replayModeEnabled: false,
                    }));
                    setBetChoice(false);
                    setSelectedCards([]);
                    setPlayLimboCards([]);
                    setWishValue(undefined);
                    setTradeSlateCards([null, null, null]);
                    setWaitingOnSubmittedMove(false);
                    clearAutoplayTimeout();
                } else if (event.type === DmTableEventType.GAME_UPDATED) {
                    if (event.game.game_hands.length < 1) {
                        return;
                    }
                    const latestHand = event.game.game_hands[event.game.game_hands.length - 1];
                    if (latestHand.history === null || latestHand.history.length < 1) {
                        return;
                    }
                    const latestEntry = latestHand.history[latestHand.history.length - 1];
                    // Slightly hacky way to check if the trade phase just ended.
                    // Check for not being in trade phase, not being in grand phase, and everyone having full hands.
                    // It's currently accurate but could break in the future.
                    if (
                        latestEntry.move === null &&
                        latestEntry.state.is_trade_phase === false &&
                        latestEntry.state.grand_phase_state === null
                    ) {
                        if (latestEntry.state.positions.map((position) => position.hand_cards.length === 14).every((isFull) => isFull)) {
                            setSelectedCards([]);
                        }
                    }
                }
            };
            const unsubscribe = webSocketClient.subscribeToFeedOfSubscribedTableEvents(onTableEvent);
            return unsubscribe;
        }
    }, [webSocketClient, setReplayState]);

    // when the hand changes, reset the bet choice
    useEffect(() => {
        setBetChoice(false);
    }, [table.id, tc.nowplayState.nowplayHandCursor]);

    // Autopass?
    useEffect(() => {
        clearAutoplayTimeout();
        if (game.game_hands.length === 0 || tc.nowplayState.nowplayHandCursor === -1) {
            return;
        }
        if (tc.replayState.replayModeEnabled) {
            return;
        }
        if (waitingOnSubmittedMove || autoplayTimeoutRef.current) {
            return;
        }
        if (playerSeat === -1) {
            return;
        }
        if (tc.gameplayContext === undefined) {
            return;
        }
        const playState = tc.gameplayContext.displayedPlayState;
        if (playState.is_engine_turn || playState.phase !== DmPlayStatePhase.PLAY_PHASE) {
            return;
        }
        if (playState.active_player !== playerSeat) {
            return;
        }
        let scheduleAutopass = false;
        if (autopassCantPlay) {
            if (
                (playState.legal_actions.length === 1 && playState.legal_actions[0] === ACTION_ID_PASS) ||
                (playState.legal_actions.length === 2 &&
                    playState.legal_actions.includes(ACTION_ID_PASS) &&
                    playState.legal_actions.includes(ACTION_ID_BET))
            ) {
                scheduleAutopass = true;
            }
        }
        if (!scheduleAutopass && autopassNoCards) {
            if (
                playState.positions[playerSeat].hand_cards.length < 4 &&
                playState.winning_combination !== null &&
                playState.positions[playerSeat].hand_cards.length < playState.winning_combination.cards.length &&
                playState.winning_player !== playerSeat
            ) {
                scheduleAutopass = true;
            }
        }

        if (scheduleAutopass) {
            const autopassIfApplicable = () => {
                clearAutoplayTimeout();
                setWaitingOnSubmittedMove(true);
                playPop();
                const response = apiClient.makeMove(
                    table.id,
                    MoveUtil.getMoveFromCards(playState.active_player, [], playState.winning_combination ?? undefined)
                );
                response.then(() => {
                    setWaitingOnSubmittedMove(false);
                    setWishValue(undefined);
                });
            };
            const randomTimeout = Math.floor(Math.random() * 300 + Math.random() * 300 + 800);
            // Set the timeout
            autoplayTimeoutRef.current = setTimeout(autopassIfApplicable, randomTimeout);
            // Also set end time state for visual feedback
            const endTime = Date.now() + randomTimeout;
            setAutoplayTimeoutEnd(endTime);
        }
    }, [
        autopassNoCards,
        autopassCantPlay,
        selectedCards,
        playerSeat,
        table.id,
        apiClient,
        game,
        tc.nowplayState,
        tc.replayState.replayModeEnabled,
        tc.gameplayContext,
        waitingOnSubmittedMove,
    ]);

    // Set suggested plays
    useEffect(() => {
        if (
            game === null ||
            playerSeat === -1 ||
            tc.nowplayState.nowplayHandCursor === -1 ||
            tc.nowplayState.nowplayMoveCursor === -1 ||
            game.game_hands.length <= tc.nowplayState.nowplayHandCursor
        ) {
            return;
        }
        const history = game.game_hands[tc.nowplayState.nowplayHandCursor].history;
        if (history === null || history.length <= tc.nowplayState.nowplayMoveCursor) {
            return;
        }
        const playState = history[tc.nowplayState.nowplayMoveCursor].state;
        if (
            playState.is_engine_turn ||
            playState.is_trade_phase ||
            playState.grand_phase_state !== null ||
            playState.is_hand_over ||
            playState.give_dragon ||
            playState.make_wish ||
            playState.active_player !== game.participants.findIndex((player) => player?.user?.id === user.id)
        ) {
            setSuggestedPlays([]);
        } else {
            const cardsReceived = [
                playState.positions[(playerSeat + 1) % 4].trade_slate![2],
                playState.positions[(playerSeat + 2) % 4].trade_slate![1],
                playState.positions[(playerSeat + 3) % 4].trade_slate![0],
            ];
            const suggestedPlays = ActionMoveAssistant.suggestMoves(
                playState.legal_actions,
                selectedCards,
                playState.positions[playState.active_player].hand_cards,
                [cardsReceived[0], cardsReceived[2]],
                [cardsReceived[1]],
                playState.winning_combination ?? undefined,
                playState.wish_rank ?? undefined
            );
            setSuggestedPlays(suggestedPlays);
        }
    }, [game, table, user, selectedCards, tc.nowplayState, playerSeat]);

    // Save convenience options to localStorage
    useEffect(() => {
        localStorage.setItem("autopassNoCards", autopassNoCards.toString());
    }, [autopassNoCards]);

    useEffect(() => {
        localStorage.setItem("autopassCantPlay", autopassCantPlay.toString());
    }, [autopassCantPlay]);

    const updateSettings = (settings: Partial<DmTableSettings>) => {
        apiClient.updateTableSettings(table.id, settings);
    };

    if (tc.loading) {
        return <LoadingDiv>Loading...</LoadingDiv>;
    }

    const startTable = () => {
        apiClient.startTable(table.id);
    };

    const addBot = (seat: number) => {
        apiClient.addBot(table.id, seat);
    };

    const removeBot = (seat: number) => {
        apiClient.removeBot(table.id, seat);
    };

    const changeSeat = (newSeat: number) => {
        apiClient.changeSeat(table.id, newSeat);
    };

    const leaveTable = () => {
        navigate("/");
        apiClient.leaveTable(table.id);
    };

    if (game.status === GameStatus.NOT_STARTED || tc.gameplayContext === undefined) {
        return (
            <TableSetup
                table={table}
                addBot={addBot}
                startTable={startTable}
                updateSettings={updateSettings}
                changeSeat={changeSeat}
                removeBot={removeBot}
                leaveTable={leaveTable}
                joinTable={(seat) => joinTable(table.id, seat)}
            />
        );
    }

    const gc = tc.gameplayContext;
    const playState = gc.displayedPlayState;

    const isReplayOfFinishedHand =
        tc.replayState.replayModeEnabled &&
        (game.game_hands.length > tc.replayState.replayHandCursor + 1 ||
            game.game_hands[tc.replayState.replayHandCursor].play_state.is_hand_over);

    const thisPlayerIsActive = playerSeat === playState.active_player;

    // Whenever game changes such that it's not the trade phase, if we have a trade slate, clear it
    if (!playState.is_trade_phase && tradeSlateCards.some((card) => card !== null)) {
        setTradeSlateCards([null, null, null]);
    }

    const getPlayFromSelectedCards = () => {
        if (playState.make_wish) {
            if (wishValue === undefined) {
                throw new Error("Wish value must be set before calling getPlayFromSelectedCards");
            }
            return MoveUtil.getMakeWishMove(playState.active_player, wishValue === "No wish" ? null : wishValue);
        }

        return MoveUtil.getMoveFromCards(playState.active_player, selectedCards, playState.winning_combination ?? undefined);
    };

    const nextHand = () => {
        if (game) {
            const response = apiClient.nextHand(table.id);
            response.then(() => {
                setSelectedCards([]);
                setPlayLimboCards([]);
            });
        }
    };

    const nextGame = () => {
        apiClient.newGame(table.id);
        setScoreModalOpen(false);
    };

    const submitTrade = () => {
        playPop();
        if (tradeSlateDirty) {
            if (tradeSlateCards.includes(null) || tradeSlateCards.length !== 3) {
                return;
            }
            setTradeSlateDirty(false);
            apiClient.chooseTrade(table.id, { position: playerSeat, trade_slate: tradeSlateCards } as DmTradeSlateChoice);
        }
    };

    const submitCancelTrade = () => {
        playDrum();
        setTradeSlateDirty(false);
        setTradeSlateCards([null, null, null]);
        apiClient.chooseTrade(table.id, { position: playerSeat, trade_slate: null } as DmTradeSlateChoice);
    };

    const playSuggestedCards = (suggestedPlay: DmCombination) => {
        betIfSelected().then(() => {
            playPop();
            const response = apiClient.makeMove(
                table.id,
                MoveUtil.getMoveFromCards(playState.active_player, suggestedPlay.cards, playState.winning_combination ?? undefined)
            );
            clearAutoplayTimeout();
            setWaitingOnSubmittedMove(true);
            setSelectedCards([]);
            setPlayLimboCards([...suggestedPlay.cards]);
            response.then(() => {
                setSelectedCards([]);
                setPlayLimboCards([]);
                setWishValue(undefined);
                setWaitingOnSubmittedMove(false);
            });
        });
    };

    const playSelectedCards = () => {
        betIfSelected().then(() => {
            playPop();
            const response = apiClient.makeMove(table.id, getPlayFromSelectedCards());

            clearAutoplayTimeout();
            setWaitingOnSubmittedMove(true);
            setSelectedCards([]);
            setPlayLimboCards([...selectedCards]);
            // TODO: This should be on receiving the websocket event instead
            response.then(() => {
                setSelectedCards([]);
                setPlayLimboCards([]);
                setWishValue(undefined);
                setWaitingOnSubmittedMove(false);
            });
        });
    };

    const betIfSelected = () => {
        if (betChoice) {
            if (thisPlayerIsActive && !playState.is_trade_phase && !isGrandPhase && betAvailable) {
                playPop();
                const response = apiClient.makeMove(table.id, MoveUtil.getBetMove(playState.active_player));
                clearAutoplayTimeout();
                setWaitingOnSubmittedMove(true);
                return response;
            }
        }
        return Promise.resolve();
    };

    const toggleSelectCard = (cardId: CardId) => {
        const newSelectedCards = selectedCards.includes(cardId)
            ? selectedCards.filter((selectedCardId) => selectedCardId !== cardId)
            : selectedCards.concat(cardId);
        setSelectedCards(newSelectedCards);
    };

    const onCardClick = (cardId: CardId) => {
        if (playerSeat !== -1 && playState.positions[playerSeat]?.hand_cards.includes(cardId)) {
            toggleSelectCard(cardId);
        }
    };

    const chooseGrandBet = (choice: boolean) => {
        playPop();
        setWaitingOnSubmittedMove(true);
        const response = apiClient.chooseGrandBet(table.id, playerSeat, choice);
        response.then(() => {
            setWaitingOnSubmittedMove(false);
        });
    };

    const tradeSelect = (direction: "left" | "center" | "right") => {
        if (!playState.is_trade_phase) {
            return;
        }
        if (selectedCards.length === 1) {
            const newTradeSlateCards = [...tradeSlateCards];
            switch (direction) {
                case "left":
                    if (newTradeSlateCards[0] !== selectedCards[0]) {
                        newTradeSlateCards[0] = selectedCards[0];
                    }
                    break;
                case "center":
                    if (newTradeSlateCards[1] !== selectedCards[0]) {
                        newTradeSlateCards[1] = selectedCards[0];
                    }
                    break;
                case "right":
                    if (newTradeSlateCards[2] !== selectedCards[0]) {
                        newTradeSlateCards[2] = selectedCards[0];
                    }
                    break;
            }
            setTradeSlateDirty(true);
            setTradeSlateCards(newTradeSlateCards);
            setSelectedCards([]);
        } else {
            const newTradeSlateCards = [...tradeSlateCards];
            switch (direction) {
                case "left":
                    if (newTradeSlateCards[0] !== null) {
                        newTradeSlateCards[0] = null;
                    }
                    break;
                case "center":
                    if (newTradeSlateCards[1] !== null) {
                        newTradeSlateCards[1] = null;
                    }
                    break;
                case "right":
                    if (newTradeSlateCards[2] !== null) {
                        newTradeSlateCards[2] = null;
                    }
                    break;
            }
            setTradeSlateDirty(true);
            setTradeSlateCards(newTradeSlateCards);
            setSelectedCards([]);
        }
    };

    const seats = [];
    const activeAreas = [];
    let trickInfo = <></>;
    let controls = <></>;
    const isScore = playState.is_hand_over;
    const isGrandPhase = playState.grand_phase_state !== null;
    const currentTrickMoves = MoveUtil.getPlayOrPassMovesFromCurrentTrick(gc.truncatedHandHistory);
    const lastMovesPerPlayer = [0, 1, 2, 3].map((player) => currentTrickMoves.filter((move) => move.position === player).pop());
    const seatAtBottom = playerSeat === -1 ? 0 : playerSeat;
    const seatsAndAlignments = [
        { perspectiveSeat: 0, seat: seatAtBottom, alignment: Alignment.BOTTOM },
        { perspectiveSeat: 1, seat: (seatAtBottom + 1) % 4, alignment: Alignment.LEFT },
        { perspectiveSeat: 2, seat: (seatAtBottom + 2) % 4, alignment: Alignment.TOP },
        { perspectiveSeat: 3, seat: (seatAtBottom + 3) % 4, alignment: Alignment.RIGHT },
    ];

    const allHandsVisible = isScore || isReplayOfFinishedHand || (user.privilege_level === 2 && uiSettings.revealPlayerHands);
    const markedCards = getMarkedCards(playState, playerSeat, allHandsVisible);

    for (const { perspectiveSeat, seat, alignment } of seatsAndAlignments) {
        const i = seat;
        const handIsVisible = allHandsVisible || (perspectiveSeat === 0 && playerSeat !== -1);
        const handSource = playState.grand_phase_state ? playState.grand_phase_state.first_8s[i] : playState.positions[i].hand_cards;
        const filteredHandCards =
            perspectiveSeat !== 0 ? handSource : handSource.filter((cardId) => tradeSlateCards.indexOf(cardId) === -1);
        const cardDetailsList = filteredHandCards.map((cardId) =>
            handIsVisible || markedCards[cardId] ? CardDetails.getCardDetails(cardId) : null
        );
        cardDetailsList.sort((a, b) => {
            if (a === null) {
                return -1;
            }
            if (b === null) {
                return 1;
            }
            return a.sortOrder - b.sortOrder;
        });

        const cards = cardDetailsList
            .filter((c) => c === null || playLimboCards.indexOf(c.cardId) === -1)
            .map((cardDetails, index) => {
                return (
                    <Card
                        key={cardDetails ? cardDetails.cardId : `unknown_card_${i}_${index}`}
                        cardDetails={cardDetails}
                        onClick={onCardClick}
                        selected={!!cardDetails && selectedCards.indexOf(cardDetails.cardId) !== -1}
                        textOnly={false}
                        contextId={table.id + gc.displayedGameHandIndex}
                        sideAlignment={alignment === Alignment.LEFT ? "left" : alignment === Alignment.RIGHT ? "right" : undefined}
                        cardMark={cardDetails ? markedCards[cardDetails.cardId] : undefined}
                        overlayCardBack={!handIsVisible}
                    />
                );
            });
        const limboCards = cardDetailsList
            .filter((c) => c !== null && playLimboCards.indexOf(c.cardId) !== -1)
            .map((cardDetails, index) => {
                return (
                    <Card
                        key={cardDetails ? cardDetails.cardId : `unknown_card_${i}_${index}`}
                        cardDetails={cardDetails}
                        onClick={onCardClick}
                        selected={!!cardDetails && selectedCards.indexOf(cardDetails.cardId) !== -1}
                        textOnly={false}
                        contextId={table.id + gc.displayedGameHandIndex}
                        inPlayLimbo={true}
                    />
                );
            });
        // TODO: reimplement hidden cards
        // for (let cardCount = cards.length; cardCount < cardLocations.byPlayer[i].handSize; cardCount++) {
        //     cards.push(
        //         <Card
        //             key={`unknown_card_${i}_${cardCount}`}
        //             cardDetails={CardDetails.UNKNOWN_CARD}
        //             onClick={() => {}}
        //             selected={false}
        //         />
        //     );
        // }
        const hand = (
            <Hand key={i} alignment={alignment}>
                {cards}
            </Hand>
        );
        const latestMove = lastMovesPerPlayer[i];
        const isThisPlayer = i === playerSeat;
        const isActivePlayer =
            (i === playState.active_player && !playState.is_trade_phase && !isGrandPhase) ||
            (playState.is_trade_phase && playState.positions[i].trade_slate === null) ||
            (playState.grand_phase_state !== null && playState.grand_phase_state.grand_bet_choices[i] === null);
        const isWinningPlayer = i === playState.winning_player;
        const seatDiv = (
            <Seat
                player={game.participants[i]}
                position={playState.positions[i]}
                active={isActivePlayer}
                alignment={alignment}
                hand={hand}
                limboCards={limboCards.length > 0 ? limboCards : undefined}
                nsTeam={i % 2 === 0}
                joinInSeat={() => joinTable(table.id, i)}
            ></Seat>
        );
        seats.push(seatDiv);

        if (isThisPlayer && playState.is_trade_phase) {
            const activeAreaDiv = (
                <TradeArea>
                    <TradeCardSlot
                        cardId={tradeSlateCards[0]}
                        direction="left"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("left")}
                        contextId={table.id + gc.displayedGameHandIndex}
                    ></TradeCardSlot>
                    <TradeCardSlot
                        cardId={tradeSlateCards[1]}
                        direction="center"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("center")}
                        contextId={table.id + gc.displayedGameHandIndex}
                    ></TradeCardSlot>
                    <TradeCardSlot
                        cardId={tradeSlateCards[2]}
                        direction="right"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("right")}
                        contextId={table.id + gc.displayedGameHandIndex}
                    ></TradeCardSlot>
                </TradeArea>
            );
            activeAreas.push(activeAreaDiv);
        } else {
            const activeAreaDiv = (
                <SeatActivePlay winning={isWinningPlayer}>
                    {latestMove &&
                        (latestMove.combination.combination_type === CombinationType.PASS ? (
                            <Pass />
                        ) : (
                            latestMove.combination.cards.map((cardId) => (
                                <Card
                                    key={cardId}
                                    cardDetails={CardDetails.getCardDetails(cardId)}
                                    onClick={onCardClick}
                                    selected={selectedCards.indexOf(cardId) !== -1}
                                    textOnly={false}
                                    contextId={table.id + gc.displayedGameHandIndex}
                                />
                            ))
                        ))}
                    {latestMove &&
                        latestMove.combination.combination_type === CombinationType.SINGLE &&
                        latestMove.combination.cards[0] === CardId.PHOENIX && (
                            <div className="phoenixRankExplanation">({RANKS[latestMove.combination.rank]})</div>
                        )}
                </SeatActivePlay>
            );
            activeAreas.push(activeAreaDiv);
        }
    }

    trickInfo = <TrickInfo playState={playState}></TrickInfo>;

    // Whether the current player can bet
    const betAvailable =
        thisPlayerIsActive &&
        playState.positions[playState.active_player].bet === false &&
        playState.positions[playState.active_player].hand_cards.length === 14 &&
        !playState.positions.some((p) => p.finished_order === 1);

    const activeGameRequest = game.game_requests && game.game_requests.find((gameRequest) => gameRequest.pending);
    if (activeGameRequest) {
        const isOwnRequest = activeGameRequest.initiator.id === user.id;
        controls = (
            <Controls
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 800,
                    damping: 20,
                }}
                key={"gameRequestControls"}
            >
                <GameRequest request={activeGameRequest}></GameRequest>
                <RequestDeclineButton onClick={() => apiClient.denyRequest(table.id, activeGameRequest.id)}>
                    {isOwnRequest ? "Cancel" : "Decline"}
                </RequestDeclineButton>
                {!isOwnRequest && (
                    <RequestAcceptButton onClick={() => apiClient.approveRequest(table.id, activeGameRequest.id)}>
                        Accept
                    </RequestAcceptButton>
                )}
            </Controls>
        );
    } else if (!tc.replayState.replayModeEnabled) {
        controls =
            playerSeat === -1 ? (
                <Controls></Controls>
            ) : (
                <PlayControlsBar
                    apiClient={apiClient}
                    waitingOnSubmittedMove={waitingOnSubmittedMove}
                    selectedCards={selectedCards}
                    suggestedPlays={suggestedPlays}
                    wishValue={wishValue}
                    setWishValue={setWishValue}
                    betChoice={betChoice}
                    setBetChoice={setBetChoice}
                    playSelectedCards={playSelectedCards}
                    playSuggestedCards={playSuggestedCards}
                    chooseGrandBet={chooseGrandBet}
                    submitTrade={submitTrade}
                    submitCancelTrade={submitCancelTrade}
                    tradeSlateCards={tradeSlateCards}
                    tradeSlateDirty={tradeSlateDirty}
                    autoplayTimeoutEnd={autoplayTimeoutEnd}
                />
            );
    } else {
        // replay mode
        if (game.status !== GameStatus.COMPLETE) {
            controls = (
                <Controls>
                    <button onClick={exitReplayMode} className="exitReplayButton easyButton" key="exitReplayButton">
                        Exit replay
                    </button>
                </Controls>
            );
        } else {
            controls = <></>;
        }
    }
    const cardsTradedToThisPlayer =
        playerSeat !== -1 && !playState.is_trade_phase && !playState.positions.some((p) => p.trade_slate === null)
            ? [
                  playState.positions[(playerSeat + 1) % 4].trade_slate![2],
                  playState.positions[(playerSeat + 2) % 4].trade_slate![1],
                  playState.positions[(playerSeat + 3) % 4].trade_slate![0],
              ]
            : null;

    return (
        <div className="table">
            <ScoreModal isOpen={scoreModalOpen} setIsOpen={setScoreModalOpen} nextGameFunc={nextGame} />
            <LayoutGroup>
                <ReplayBar
                    leaveTable={leaveTable}
                    requestUndo={(hand: number, move: number) => apiClient.requestUndo(table.id, hand, move)}
                    requestAbort={() => apiClient.requestAbort(table.id)}
                    showScoreModal={showScoreModal}
                />
                <div className="horizontalFlexbox">
                    {user.privilege_level === 2 && uiSettings.showBotPanel && (
                        <AiAssessmentPanel
                            apiClient={apiClient}
                            tableId={table.id}
                            replayState={tc.replayState}
                            nowplayState={tc.nowplayState}
                            playState={playState}
                        />
                    )}
                    <GameLayout seats={seats} activeAreas={activeAreas} trickInfo={trickInfo} controls={controls}></GameLayout>
                    <SidebarContainer>
                        <ConvenienceOptionsPanel
                            autopassNoCards={autopassNoCards}
                            setAutopassNoCards={setAutopassNoCards}
                            autopassCantPlay={autopassCantPlay}
                            setAutopassCantPlay={setAutopassCantPlay}
                        />
                        <HistoryPanel
                            nextHandCallback={nextHand}
                            cardsGiven={playerSeat === -1 ? null : playState.positions[playerSeat].trade_slate}
                            cardsReceived={playerSeat === -1 ? null : cardsTradedToThisPlayer}
                            showScoreModal={showScoreModal}
                        />
                    </SidebarContainer>
                </div>
            </LayoutGroup>
        </div>
    );
};
