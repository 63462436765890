import { CardId, PosId, Rank } from "../client/basic-types";
import { CombinationType, DmCombination, DmMove, DmHistoryEntry } from "../client/server-types-python";
import { CardDetails, Suit } from "./CardDetails";

export class MoveUtil {
    private static getLengthOneTypeAndRank(
        sortedCardDetails: CardDetails[],
        winningCombination?: DmCombination
    ): [type: CombinationType, rank: number] {
        let type = CombinationType.PASS;
        let rank = 0;
        if (sortedCardDetails[0].cardId === CardId.DOG) {
            type = CombinationType.DOG;
        } else {
            type = CombinationType.SINGLE;
            rank = sortedCardDetails[0].rank;
            if (sortedCardDetails[0].cardId === CardId.PHOENIX) {
                if (winningCombination) {
                    rank = winningCombination.rank;
                    if (rank > 14) {
                        throw new Error("Invalid play");
                    }
                }
            }
        }

        return [type, rank];
    }

    public static canFulfillWish(cards: CardDetails[], winningCombination?: DmCombination, wish?: number): boolean {
        if (wish === undefined) {
            return false;
        }
        const matchingRankCards = cards.filter((card) => card.rank === wish);
        if (matchingRankCards.length === 0) {
            return false;
        }
        if (winningCombination === undefined) {
            return true;
        }
        const bombIsWinning =
            winningCombination.combination_type === CombinationType.QUAD_BOMB ||
            winningCombination.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB;
        const minBombSize = bombIsWinning ? winningCombination.cards.length : 0;
        const minBombRank = bombIsWinning ? winningCombination.rank : 0;
        const canBomb = MoveUtil._canBombUsingWishRank(cards, minBombSize, minBombRank, wish, matchingRankCards);
        if (canBomb) {
            return true;
        }
        if (bombIsWinning) {
            return false;
        }
        let hasPhoenix = cards.some((card) => card.cardId === CardId.PHOENIX);
        switch (winningCombination.combination_type) {
            case CombinationType.SINGLE:
                return winningCombination.rank < wish;
            case CombinationType.PAIR:
                return winningCombination.rank < wish && (matchingRankCards.length >= 2 || hasPhoenix);
            case CombinationType.TRIPLE:
                return winningCombination.rank < wish && (matchingRankCards.length === 3 || (hasPhoenix && matchingRankCards.length === 2));
            case CombinationType.STAIRS:
                const numSteps = winningCombination.cards.length / 2;
                const lowestPossibleRank = winningCombination.rank - numSteps + 2;
                if (wish < lowestPossibleRank) {
                    return false;
                }
                // Make sure we have enough of the wished rank
                if (matchingRankCards.length === 1) {
                    if (hasPhoenix) {
                        hasPhoenix = false;
                    } else {
                        return false;
                    }
                }
                let foundSteps = 1;
                let r = wish + 1;
                const rankCounts = new Array(13).fill(0);
                for (const card of cards) {
                    if (card.suit !== Suit.UNKNOWN) {
                        rankCounts[card.rank - 2]++;
                    }
                }
                while (foundSteps < numSteps && r <= 14) {
                    if (rankCounts[r - 2] >= 2) {
                        foundSteps++;
                    } else if (rankCounts[r - 2] === 1 && hasPhoenix) {
                        foundSteps++;
                        hasPhoenix = false;
                    } else {
                        break;
                    }
                    r++;
                }
                r = wish - 1;
                while (foundSteps < numSteps && r >= lowestPossibleRank) {
                    if (rankCounts[r - 2] >= 2) {
                        foundSteps++;
                    } else if (rankCounts[r - 2] === 1 && hasPhoenix) {
                        foundSteps++;
                        hasPhoenix = false;
                    } else {
                        break;
                    }
                    r--;
                }
                return foundSteps === numSteps;
            case CombinationType.FULL_HOUSE:
                const wishCanBeTriple = wish > winningCombination.rank;
                if (matchingRankCards.length === 1) {
                    if (hasPhoenix) {
                        hasPhoenix = false;
                    } else {
                        return false;
                    }
                }
                // Now we know we have at least a pair of the wished rank
                let hasWishTriple = false;
                if (wishCanBeTriple) {
                    if (matchingRankCards.length === 3) {
                        hasWishTriple = true;
                    } else if (matchingRankCards.length === 2 && hasPhoenix) {
                        hasPhoenix = false;
                        hasWishTriple = true;
                    }
                }
                if (hasWishTriple) {
                    // just need to find a pair
                    if (hasPhoenix) {
                        // In fact, just need ANY normal card
                        return cards.some((card) => card.rank !== wish && card.suit !== Suit.UNKNOWN);
                    }
                    // But with no phoenix, we need a pair
                    const rankCounts = new Array(13).fill(0);
                    for (const card of cards) {
                        if (card.suit !== Suit.UNKNOWN) {
                            rankCounts[card.rank - 2]++;
                            if (rankCounts[card.rank - 2] === 2 && card.rank !== wish) {
                                return true;
                            }
                        }
                    }
                } else {
                    if (hasPhoenix) {
                        // Need a high-enough pair to triple up with
                        const rankCounts = new Array(13).fill(0);
                        for (const card of cards) {
                            if (card.rank > winningCombination.rank && card.suit !== Suit.UNKNOWN) {
                                rankCounts[card.rank - 2]++;
                                if (rankCounts[card.rank - 2] === 2 && card.rank !== wish) {
                                    return true;
                                }
                            }
                        }
                    } else {
                        // Need a high-enough triple
                        const rankCounts = new Array(13).fill(0);
                        for (const card of cards) {
                            if (card.rank > winningCombination.rank && card.suit !== Suit.UNKNOWN) {
                                rankCounts[card.rank - 2]++;
                                if (rankCounts[card.rank - 2] === 3 && card.rank !== wish) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            case CombinationType.STRAIGHT: {
                const lowestPossibleRank = winningCombination.rank - winningCombination.cards.length + 2;
                if (wish < lowestPossibleRank) {
                    return false;
                }
                const ranks = new Array(13).fill(false);
                for (const card of cards) {
                    if (card.suit !== Suit.UNKNOWN) {
                        ranks[card.rank - 2] = true;
                    }
                }
                let minRank = wish;
                let maxRank = wish;
                while (maxRank < 14 && ranks[maxRank - 2]) {
                    maxRank++;
                }
                while (minRank > lowestPossibleRank && ranks[minRank - 2]) {
                    minRank--;
                }
                const sizeWithoutPhx = maxRank - minRank + 1;
                if (sizeWithoutPhx >= winningCombination.cards.length) {
                    return true;
                }
                if (maxRank < 14 && hasPhoenix) {
                    let maxRankWithPhx = maxRank + 1;
                    while (maxRankWithPhx < 14 && ranks[maxRank - 2]) {
                        maxRank++;
                    }
                    if (maxRankWithPhx - minRank + 1 >= winningCombination.cards.length) {
                        return true;
                    }
                }
                if (minRank > lowestPossibleRank && hasPhoenix) {
                    let minRankWithPhx = minRank - 1;
                    while (minRankWithPhx > lowestPossibleRank && ranks[minRank - 2]) {
                        minRank--;
                    }
                    if (maxRank - minRankWithPhx + 1 >= winningCombination.cards.length) {
                        return true;
                    }
                }
                return false;
            }
            default:
                return false;
        }
    }

    public static _canBombUsingWishRank(
        cards: CardDetails[],
        minBombSize: number,
        minBombRank: number,
        wish: number,
        matchingRankCards: CardDetails[]
    ): boolean {
        // Check for quad bomb if the existing bomb - if any - is smaller.
        if (minBombSize <= 4 && minBombRank < wish) {
            if (matchingRankCards.length === 4) {
                return true;
            }
        }
        // Check for SF bombs
        const minSfBombSize = minBombSize > 5 ? minBombSize : 5;
        for (const suit of matchingRankCards.map((card) => card.suit)) {
            const matchingSuitCards = matchingRankCards.filter((card) => card.suit === suit);
            if (matchingSuitCards.length >= minSfBombSize) {
                const suit = [false, false, false, false, false, false, false, false, false, false, false, false, false];
                for (const card of matchingSuitCards) {
                    suit[card.rank - 2] = true;
                }
                let length = 1;
                let i = wish - 2 + 1;
                while (i < 13 && suit[i]) {
                    length++;
                    i++;
                }
                const rank = i + 2;
                i = wish - 2 - 1;
                while (i >= 0 && suit[i]) {
                    length++;
                    i--;
                }
                if (length > minSfBombSize || (length === minSfBombSize && rank > minBombRank)) {
                    return true;
                }
            }
        }

        return false;
    }

    private static getLengthTwoTypeAndRank(sortedCardDetails: CardDetails[]): [type: CombinationType, rank: number] {
        if (sortedCardDetails[1].suit === Suit.UNKNOWN) {
            throw new Error("Invalid play");
        }
        let rank = sortedCardDetails[1].rank;
        if (!(sortedCardDetails[0].cardId === CardId.PHOENIX || sortedCardDetails[0].rank === rank)) {
            throw new Error("Invalid play");
        }

        return [CombinationType.PAIR, rank];
    }

    private static getLengthThreeTypeAndRank(sortedCardDetails: CardDetails[]): [type: CombinationType, rank: number] {
        let rank = sortedCardDetails[1].rank;

        if (sortedCardDetails[1].suit === Suit.UNKNOWN) {
            throw new Error("Invalid play");
        }
        if (!(sortedCardDetails[2].rank === rank)) {
            throw new Error("Invalid play");
        }
        if (!(sortedCardDetails[0].cardId === CardId.PHOENIX || sortedCardDetails[0].rank === rank)) {
            throw new Error("Invalid play");
        }

        return [CombinationType.TRIPLE, rank];
    }

    private static getLengthFourTypeAndRank(sortedCardDetails: CardDetails[]): [type: CombinationType, rank: number] {
        let type = CombinationType.PASS;
        const rank = sortedCardDetails[3].rank;

        if (
            sortedCardDetails[1].suit === Suit.UNKNOWN ||
            sortedCardDetails[2].suit === Suit.UNKNOWN ||
            sortedCardDetails[3].suit === Suit.UNKNOWN
        ) {
            throw new Error("Invalid play");
        }

        if (sortedCardDetails[1].rank === rank) {
            type = CombinationType.QUAD_BOMB;
            if (sortedCardDetails[0].rank !== rank || sortedCardDetails[2].rank !== rank) {
                throw new Error("Invalid play");
            }
        } else {
            type = CombinationType.STAIRS;
            if (!(sortedCardDetails[1].rank === rank - 1)) {
                throw new Error("Invalid play");
            }
            if (sortedCardDetails[0].cardId === CardId.PHOENIX) {
                if (!(sortedCardDetails[2].rank === rank || sortedCardDetails[2].rank === rank - 1)) {
                    throw new Error("Invalid play");
                }
            } else {
                if (!(sortedCardDetails[0].rank === rank - 1)) {
                    throw new Error("Invalid play");
                }
                if (!(sortedCardDetails[2].rank === rank)) {
                    throw new Error("Invalid play");
                }
            }
        }

        return [type, rank];
    }

    private static getLengthFivePlusTypeAndRank(cards: CardDetails[]): [type: CombinationType, rank: number] {
        let type = CombinationType.PASS;
        let rank = cards[cards.length - 1].rank;

        if (cards.length === 5 && (cards[4].rank === cards[3].rank || cards[3].rank === cards[2].rank)) {
            type = CombinationType.FULL_HOUSE;
            if (cards[0].cardId === CardId.PHOENIX) {
                const lowRank = cards[1].rank;
                const highRank = cards[4].rank;
                if (lowRank === highRank || lowRank < 2 || highRank > 14) {
                    throw new Error("Invalid play");
                }
                if (cards[2].rank === lowRank && cards[3].rank === lowRank) {
                    rank = lowRank;
                } else if ((cards[2].rank === lowRank || cards[2].rank === highRank) && cards[3].rank === highRank) {
                    // Assume phoenix is used at the high end
                    rank = highRank;
                } else {
                    throw new Error("Invalid play");
                }
            } else {
                const lowRank = cards[0].rank;
                const highRank = cards[4].rank;
                if (lowRank === highRank || cards[1].rank !== lowRank || cards[3].rank !== highRank) {
                    throw new Error("Invalid play");
                }

                if (cards[2].rank === lowRank) {
                    rank = lowRank;
                } else if (cards[2].rank === highRank) {
                    rank = highRank;
                } else {
                    throw new Error("Invalid play");
                }
            }
        } else if (cards.length > 5 && (cards[1].rank === cards[2].rank || cards[2].rank === cards[3].rank)) {
            type = CombinationType.STAIRS;
            // Lowest legal rank in a stairs is 2
            if (rank - cards.length / 2 + 1 < 2) {
                throw new Error("Invalid play");
            }

            if (cards[0].cardId === CardId.PHOENIX) {
                let targetRank = rank;
                for (let i = cards.length - 1; i > 0; i -= 2) {
                    if (cards[i].rank !== targetRank) {
                        throw new Error("Invalid play");
                    }
                    targetRank--;
                }

                targetRank = rank;
                let usedPhoenix = false;
                for (let i = cards.length - 2; i > 0; i -= 2) {
                    if (cards[i].rank !== targetRank) {
                        if (!usedPhoenix && cards[i].rank === targetRank - 1) {
                            usedPhoenix = true;
                            targetRank--;
                        } else {
                            throw new Error("Invalid play");
                        }
                    }
                    targetRank--;
                }
            } else {
                let targetRank = rank;
                for (let i = cards.length - 1; i > 0; i -= 2) {
                    if (cards[i].rank !== targetRank || cards[i - 1].rank !== targetRank) {
                        throw new Error("Invalid play");
                    }
                    targetRank--;
                }
            }
        } else {
            rank = cards[cards.length - 1].rank;

            if (cards[0].cardId === CardId.PHOENIX) {
                type = CombinationType.STRAIGHT;
                let targetRank = rank;
                let usedPhoenix = false;
                for (let i = cards.length - 1; i > 0; i--) {
                    if (cards[i].rank !== targetRank) {
                        if (!usedPhoenix && cards[i].rank === targetRank - 1) {
                            usedPhoenix = true;
                            targetRank--;
                        } else {
                            throw new Error("Invalid play");
                        }
                    }
                    targetRank--;
                }
                if (!usedPhoenix && rank < 14) {
                    // Assume phoenix is used at the high end
                    rank++;
                }
            } else {
                // Straight or Straight Flush Bomb
                type = CombinationType.STRAIGHT_FLUSH_BOMB;
                const suit = cards[0].suit;
                let targetRank = rank;
                for (let i = cards.length - 1; i >= 0; i--) {
                    if (cards[i].rank !== targetRank) {
                        throw new Error("Invalid play");
                    }
                    targetRank--;

                    if (suit !== cards[i].suit) {
                        type = CombinationType.STRAIGHT;
                    }
                }
            }
        }

        return [type, rank];
    }

    static getPlayOrPassMovesFromCurrentTrick(history: DmHistoryEntry[]): DmMove[] {
        const plays: DmMove[] = [];
        for (let i = history.length - 1; i >= 0; i--) {
            const move = history[i].move;
            if (move === null) {
                continue;
            }
            if (move.win_trick || move.give_dragon_pos != null) {
                break;
            } else if (MoveUtil.isPassOrPlayMove(move)) {
                plays.unshift(move);
            }
        }
        return plays;
    }

    static isPassOrPlayMove(move: DmMove): boolean {
        return move.combination.combination_type === CombinationType.PASS || move.combination.cards.length > 0;
    }

    static getBetMove(position: PosId): DmMove {
        return {
            position,
            combination: {
                combination_type: CombinationType.BET,
                cards: [],
                rank: 0,
            },
            bet: true,
            wish_rank: null,
            give_dragon_pos: null,
            win_trick: false,
        };
    }

    static getMakeWishMove(position: PosId, wishRank: Rank | null): DmMove {
        return {
            position,
            combination: {
                combination_type: CombinationType.MAKE_WISH,
                cards: [],
                rank: 0,
            },
            bet: false,
            wish_rank: wishRank,
            give_dragon_pos: null,
            win_trick: false,
        };
    }

    static getGiveDragonMove(position: PosId, left: boolean): DmMove {
        return {
            position,
            combination: {
                combination_type: CombinationType.GIVE_DRAGON,
                cards: [],
                rank: 0,
            },
            bet: false,
            wish_rank: null,
            give_dragon_pos: (left ? (position + 1) % 4 : (position + 3) % 4) as PosId,
            win_trick: false,
        };
    }

    static getMoveFromCards(position: PosId, cardIds: CardId[], winningCombination?: DmCombination): DmMove {
        let type: CombinationType;
        let rank: number;

        const sortedCardDetails = cardIds.map((cardId) => CardDetails.getCardDetails(cardId)).sort((a, b) => a.sortOrder - b.sortOrder);

        if (sortedCardDetails.length === 0) {
            [type, rank] = [CombinationType.PASS, 0];
        } else if (sortedCardDetails.length === 1) {
            [type, rank] = this.getLengthOneTypeAndRank(sortedCardDetails, winningCombination);
        } else if (sortedCardDetails.length === 2) {
            [type, rank] = this.getLengthTwoTypeAndRank(sortedCardDetails);
        } else if (sortedCardDetails.length === 3) {
            [type, rank] = this.getLengthThreeTypeAndRank(sortedCardDetails);
        } else if (sortedCardDetails.length === 4) {
            [type, rank] = this.getLengthFourTypeAndRank(sortedCardDetails);
        } else {
            [type, rank] = this.getLengthFivePlusTypeAndRank(sortedCardDetails);
        }

        return {
            position,
            combination: {
                combination_type: type,
                cards: sortedCardDetails.map((cardDetails) => cardDetails.cardId),
                rank: rank,
            },
            bet: false,
            wish_rank: null,
            give_dragon_pos: null,
            win_trick: false,
        };
    }
}
