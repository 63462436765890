import React from "react";
import { NavBar } from "../components/NavBar";
import { UserContext } from "../contexts/UserContext";
import { LoadingDiv } from "../components/LoadingDiv";
import { Table } from "../components/Table";
import { DmGame, DmTable } from "../client/server-types-python";
import { ApiClient } from "../client/ApiClient";
import { WebSocketClient } from "../client/WebSocketClient";
import { TableContextProvider } from "../components/TableContext";
import { Navigate, useParams } from "react-router-dom";

type TablePageProps = {
    initialLoadInProgressSince: number | null;
    tablesState: DmTable[];
    apiClient: ApiClient;
    webSocketClient: WebSocketClient | null;
    setGame: (game: DmGame, tableId: string) => void;
    joinTable: (tableId: string, seat?: number) => void;
    // children: React.ReactNode;
};

export function TablePage(props: TablePageProps): JSX.Element {
    const { initialLoadInProgressSince, tablesState, apiClient, webSocketClient, setGame, joinTable } = props;
    const { tableId } = useParams();
    const userContext = React.useContext(UserContext);

    const mustReturnEarly = !userContext || !tableId;
    if (mustReturnEarly) {
        return <></>;
    }

    const isLoading = initialLoadInProgressSince !== null;
    if (isLoading) {
        return (
            <>
                <NavBar />
                <LoadingDiv>Loading...</LoadingDiv>
            </>
        );
    }

    const table = tablesState.find((table) => table.id === tableId) ?? null;
    if (table === null) {
        return <Navigate to="/" replace />;
    }

    return (
        <>
            <NavBar />
            <TableContextProvider table={table} apiClient={apiClient}>
                <Table apiClient={apiClient} webSocketClient={webSocketClient} setGame={setGame} joinTable={joinTable}></Table>
            </TableContextProvider>
        </>
    );
}
