import React from "react";
import { NavBar } from "../components/NavBar";
import { UserContext } from "../contexts/UserContext";
import { LoadingDiv } from "../components/LoadingDiv";
import { Lobby } from "../components/Lobby";
import { DmTable } from "../client/server-types-python";

type LobbyPageProps = {
    initialLoadInProgressSince: number | null;
    tablesState: DmTable[];
    joinTable: (tableId: string) => void;
    createTable: () => void;
    createBotTable: () => void;
};

export function LobbyPage(props: LobbyPageProps): JSX.Element {
    const { initialLoadInProgressSince, tablesState, joinTable, createTable, createBotTable } = props;
    const userContext = React.useContext(UserContext);
    if (!userContext) {
        return <></>;
    }
    return (
        <>
            <NavBar />
            {initialLoadInProgressSince !== null ? (
                <LoadingDiv>Loading...</LoadingDiv>
            ) : (
                <Lobby tables={tablesState} onCreateTable={createTable} onCreateBotTable={createBotTable} onJoinTable={joinTable}></Lobby>
            )}
        </>
    );
}
